#region-language-modal {

    small {
      margin-bottom: 20px;
    }

    ul {

      li {
        @include maison-bold();
        text-align: center;
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: #37383a;
        }
      }
    }
  }