
.facets-bar,
.facets-sticky-nav {

	max-width: 1440px;
	margin: 0 auto;

	.control-label {

		font-size: 11px;
		font-family: "Maison Neue Bold", Calibri, Arial, Roboto, sans-serif;
		font-weight: bold;
		letter-spacing: 0.06em;
		line-height: 38px;
		padding-left: 0px;
		padding-right: 0px;
	}

	.attribute-selector-container {

		position: relative;
		margin-top: 1px;

		.attribute-selector {

			background: #fdfdfd !important;
			border-bottom: 2px solid #e3e3e3;
			padding: 5px 10px 1px;

			&:hover,
			&.expanded,
			&:hover.expanded {

				padding: 5px 10px 1px;
				border-width: 0px;
				border-bottom: 2px solid #e3e3e3;

				.attribute {
					color: #2a2a2a;
				}
			}

			&:hover {
				
				-webkit-box-shadow: 1px 1px 0px 0px rgba(49, 49, 50, 0);
				-moz-box-shadow: 1px 1px 0px 0px rgba(49, 49, 50, 0);
				box-shadow: 1px 1px 0px 0px rgba(49, 49, 50, 0);
			}

			&.expanded,
			&:hover.expanded {

				width: calc(100% + 20px);
				margin-right: -20px;

				border: 2px solid #e3e3e3;
				padding: 13px 18px 15px;
				top: -10px;
				left: -10px;

				-webkit-box-shadow: 3px 3px 0px 0px rgba(49, 49, 50, 0.1);
				-moz-box-shadow: 3px 3px 0px 0px rgba(49, 49, 50, 0.1);
				box-shadow: 3px 3px 0px 0px rgba(49, 49, 50, 0.1);
			}

			.attribute {

				font-family: "Maison Neue Bold", Calibri, Arial, Roboto, sans-serif;
				font-size: 10px;
				font-weight: bold;
				letter-spacing: 0.1em;

				-webkit-transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);
				   -moz-transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);
					 -o-transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);
						transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);

				-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					 -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */
			}

			ul {

				li {

					&:hover,
					&.selected {
						border-bottom: none;
					}

					&.selected {
						color: #313132;
						background: transparent;
						padding: 0px;
						margin: 0 20px;
					}
				}
			}
		}
	}

	.tile-options {
		
		white-space: nowrap;
		margin-top: 4px;

		.tile-option {

			position: relative;
			min-width: 30px;
			height: 30px;

			span { 
				
				position: relative;
				display: inline-block;
				height: inherit;
				padding: 8px 7px;
				line-height: 1;
				text-align: center;
				cursor: pointer;
				background: none;
				outline: none;
				border: 1px solid #cccfd1;

				-webkit-box-shadow: 2px 2px 0px 0px rgba(49, 49, 50, 0.0);
				-moz-box-shadow:    2px 2px 0px 0px rgba(49, 49, 50, 0.0);
				box-shadow:         2px 2px 0px 0px rgba(49, 49, 50, 0.0);

				-webkit-transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), border-color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				   -moz-transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), border-color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					 -o-transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), border-color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), border-color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

				-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					 -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

				@media(min-width: 768px) and (max-width:991px){
					font-size: 11px;
				}
			}

			&:hover,
			&.selected,
			&.active {

				min-width: 30px;
				height: 30px;
				margin-left: -1px;
				margin-right: -1px;

				&:not(:first-child){
					margin-left: 9px;
				}

				span {

					border: 2px solid #cccfd1;
					padding: 7px 7px;
					background: none;

					-webkit-box-shadow: 3px 3px 0px 0px rgba(49, 49, 50, 0.1);
					-moz-box-shadow: 3px 3px 0px 0px rgba(49, 49, 50, 0.1);
					box-shadow: 3px 3px 0px 0px rgba(49, 49, 50, 0.1);
				}
			}

			&.selected,
			&.active {

				span {
					border: 2px solid #313132;
				}
			}

			&.disabled {

				color: #a9adb0;
				background: url('../../pdp-toolkit/img/content/disabled-wiggle-pattern.png') 0px 0px;
				opacity: 1;

				pointer-events: none;

				span, 
				span:hover {
					border: 1px solid #cccfd1 !important;
					color: #a9adb0 !important;
					background: none;
				}
			}

			&.disabled:hover {

				color: #a9adb0;	

				span, 
				span:hover {
					border: 1px solid #cccfd1 !important;
					color: #a9adb0 !important;
				}
			}
		}
	}
}