
/* THIS FILE SHOULD TYPICALLY BE LEFT ALONE - SERVES AS ANOTHER NORMALIZER */

html, body, input, button, textarea {
	// clean text look
	-webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6, 
ol, ul, li, 
a, div, span, p, img,
td, table, tbody, hr, iframe,
button, input, textarea, select {
	
	background: transparent;
	//font-weight: normal;
	border: 0 none;
	margin: 0;
	padding: 0;

	// hyphenation

	-webkit-hyphens:manual;
	-moz-hyphens:manual;
	hyphens:manual;

	// dragging
	
	user-drag: none; 
	-moz-user-select: none;
	-webkit-user-drag: none;

	// kerning
	
	font-feature-settings: "kern";
	-moz-font-feature-settings: "kern=1";
	-ms-font-feature-settings: "kern";
	-o-font-feature-settings: "kern";
	//-webkit-font-feature-settings: "kern"; // big performance hit, use wisely

	-webkit-appearance: none;
	-webkit-border-radius:0; 
	    border-radius:0;

	// disable click rectangle
	
	&:not(input) {    
		-webkit-touch-callout: none;
		-webkit-user-select: none; /* Webkit */
		-moz-user-select: none;    /* Firefox */
		-ms-user-select: none;     /* IE 10  */	
		-o-user-select: none; /* Currently not supported in Opera but will be soon */
		user-select: none;
	}
}

a, a:visited, a:focus, a:active, a:hover,
button, button:visited, button:focus, button:active, button:hover{
    outline:0 none !important;
}

button {
	cursor: pointer;
	overflow: visible !important;
}

//