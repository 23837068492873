
#added-to-cart-overlay {

	.product-callout-thumb {

		margin-right: -20px;

		img {
			margin-left: 0px;
		}
	}

	.product-callout-desc {

		right: -40px;

		@media(min-width: 768px) and (max-width:991px){
			margin-left: 0px;
			right: -40px;
		}
	}

	.horizontal-sm {

		left: 0px;
		text-align: left;
		margin: 23px 0 15px 0px;

		&.second-hr {
			margin: 23px 0 23px 0px;
		}
	}

	small {
		margin-top: 18px;
	}

	h2 {
		margin-top: 12px;
	}

	span {
		margin-bottom: 26px;
	}

	p {
		line-height: 1;
		margin-top: 9px;
	}

	button span.title {
		margin-bottom: 0px;
	}

	.btn-cart {
		margin-top: 28px;
	}

	.item-cell-row {
		
		display: inline-block;
		position: relative;
		width: 100%;
		padding: 0 60px;

		.item-cell {

		}
	}
}