
footer {

	max-width: 1920px;
	margin: 0 auto;

	.footer-newsletter {
		height: initial;
		padding: 40px 0 42px;
	}

	.footer-host-selector-anchor span {
		white-space: nowrap;
	}

	.footer-newsletter-content {

		text-align: center;
		max-width: initial;

		form { 
			@include inline-block;
			white-space: nowrap;
		}

		.footer-newsletter-label {
			
			@include inline-block;
			@include maison-medium();
			
			top: -19px;
			letter-spacing: 0.01em;
			white-space: nowrap;
			width: initial;
			margin-right: 55px;
			float: none;
		}
	
		.footer-newsletter-input-container {
			
			@include inline-block;
			
			white-space: nowrap;
			width: initial;
			min-width: 340px;
			border: 0 none;
			float: none;

			input {

				width: 100%;
				border: 2px solid #818181;
				line-height: 50px;
				height: 50px;

				&:hover {
					border-color: #f0f1f3;
				}

				&:focus, &:active {
					border-color: #f0f1f3;
				}
				
				-webkit-transition: border-color 600ms cubic-bezier(0.340, 0.065, 0.130, 1);
				-webkit-transition: border-color 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				   -moz-transition: border-color 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				     -o-transition: border-color 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				        transition: border-color 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

				-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
				-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
			}

			button {
				@include absolute;
				left: auto;
				right: 0px;
				margin-top: 0px;
				line-height: 50px;
				height: 50px;
			}
		}
	}

	.footer-bottom-column-link {

		white-space: nowrap;
		margin-top: 23px;

		@media(min-width: 768px) and (max-width:991px){

			height: 42px !important;
			line-height: 42px !important;
		}
	}

	a.footer-bottom-column-link,
	a.footer-host-selector-anchor {
		
		margin-top: 21px;

		span {

			position: relative;
			display: inline-block;
			padding-bottom: 2px;
			border-bottom: 1px solid rgba(255,255,255,0);
					
			-webkit-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1);
			-webkit-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			   -moz-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			     -o-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			        transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */

			&:hover {
				border-bottom: 1px solid rgba(255,255,255,0.6);
			}
		}
	}

	.footer-bottom-column-sublink {
		margin-top: 18px;
		letter-spacing: 0.07em;
	}

	.footer-bottom-column-social-section:not(:first-child){
		margin-top: 33px;
	}

	.footer-bottom-column-social-section {

		@media(min-width: 768px) and (max-width:991px){

			height: 42px !important;
			margin-top: 25px !important;

			&.women {
				display: block !important;
			}

			.bbg {
				display: none;
			}

			.footer-bottom-column-link {
				padding-left: 25%;
				text-align: left;
				width: auto;
				margin-top: 4px;
			}
		}
	}

	.footer-bottom-social-links {
		
		margin-top: 13px;
		white-space: nowrap;
		overflow: visible;
		margin-left: -13px;

		a {
			float: none;
			display: inline-block;
			width: 40px;
			margin: 0;
			text-align: center;

			@media(min-width: 992px) and (max-width:1199px){
				font-size: 18px;
				margin-right: 12px;
			}

			@media(min-width: 768px) and (max-width:991px){
				
				width: 30px !important;
				line-height: 42px !important;
				height: 42px !important;

				i {
					font-size: 18px !important;
					line-height: 42px !important;
				}
			}
		}

		@media(min-width: 768px) and (max-width:991px){
			
			margin-left: -10px !important;
			padding-left: 0px !important;
			position: absolute !important;
			float: none !important;
			left: 50%;
		}
	}

	.footer-content-copyright {

		@media(min-width: 768px) and (max-width:991px){
			margin-top: -107px;
		}
	}

	.footer-content {

		@media(min-width: 768px) and (max-width:991px){
			padding-bottom: 40px;
		}
	}

	.footer-content-bottom {
		overflow: visible;
	}

	.footer-content-nav {

		a {
			&:hover {
				color: #fff;
			}

			&:active, &:focus {
				color: #fff;
			}
		}

	}
}