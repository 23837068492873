
.gender-community {

	@media(min-width: 1200px) and (max-width:1479px){

	}

	@media(min-width: 992px) and (max-width:1199px){
		margin-top: 60px;
	}

	@media(min-width: 768px) and (max-width:991px){
		margin-top: 60px;
	}

	.gender-community-main-wrapper {
		
		background: transparent;
		
		@media(min-width: 1200px) and (max-width:1479px){

		}

		@media(min-width: 992px) and (max-width:1199px){
			margin-top: 87px;
		}

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 87px;
		}
	}

	.gender-community-main {
		overflow: hidden;
		background: transparent;
	}

	.gender-community-main-content {
		
		max-width: 80%;

		h2 {

			position: relative;
			letter-spacing: 0.006em;
			line-height: 77px;
			margin-left: -4px;
			text-transform: none;

			.line-wrapper {
				width: 100% !important;
				overflow: hidden;
			}

			.line {
				width: 100% !important;
				white-space: nowrap;
			}
		}

		p {
			@include maison-bold();
			letter-spacing: 0.3em;
			margin-top: 31px;
			margin-bottom: 31px;
			line-height: 1;
			overflow: hidden;

			span {
				display: inline-block;
				position: relative;
			}
		}

		.btn-outline {
			margin-top: 17px;
		}

		@media(min-width: 1480px){

		}

		@media(min-width: 1200px) and (max-width:1479px){

		}

		@media(min-width: 992px) and (max-width:1199px){

		}

		@media(min-width: 768px) and (max-width:991px){

			margin-top: -120px;
			left: 32px;

			h2 {
				font-size: 36px;
				line-height: 45px;
			}

			p {
				margin-top: 18px;
				margin-bottom: 21px;
				font-size: 12px;
			}

			.btn-video-play {
				margin-bottom: -27px;
			}
		}
	}
	
	.gender-community-secondary-team-link-arrow {
		vertical-align: middle !important;
	}

	.gender-community-secondary-team-link {
		margin-top: 37px;
	}

	.gender-community-secondary-team-link-arrow {
		overflow: hidden;
	}

	.gender-community-secondary-content-wrapper {

		h2 {
			text-transform: none;
		}
		
		p.gender-community-secondary-description {
			text-transform: none;
		}

		@media(min-width: 768px) and (max-width:991px){
			width: calc(32% - 1px);
		}
	}
}