
#layout.style-guide {

	padding: 50px 50px;
	background: #f0f0f4;
	overflow: visible;

	#main-container {
		padding: 0;
		background: transparent;
		overflow: visible;
		max-width: 1760px;
	}

	.style-guide-header {

		float: left;
		position: relative;
		padding-bottom: 20px;
		padding-left: 50px;
		width: 100%;
		min-height: 100px;
		text-align: right;

		.header-logo {

			display: block;
			position: absolute;
			bottom: 35px;
			vertical-align: bottom;

			line-height: 41px;
			height: 41px;
			width: 60px;
		}

		.style-guide-page-links {

			display: inline-block;
			width: 100%;
			max-width: 1410px;
			text-align: right;
			padding-left: 20%;
			padding-right: 20px;

			li {
				
				display: inline-block;
				position: relative;
				height: 40px;
				margin: 0 2px 0;
				padding: 0 20px;
				font-size: 14px;
				line-height: 40px;

				&.break {
					clear: both;
				}

				a {

					color: #2a2a2a;
					border-bottom: 2px solid rgba(42,42,42,0);
					padding-bottom: 5px;

					-webkit-transition: border 140ms ease-out;
						-moz-transition: border 140ms ease-out;
						-o-transition: border 140ms ease-out;
						transition: border 140ms ease-out;

					&:hover {
						border-bottom: 2px solid rgba(42,42,42,0.12);
					}
				}

				@include maison-bold();
			}
		}
	}

	.style-guide-region {

		&.full-bleed {
			margin: 0 -100px;
			width: auto;
		}
	}

	.item-details {
		
		position: relative;
		float: left;
		padding: 0px 50px 50px;
		margin-bottom: 50px;
		background: #fdfdfd;

		>.col-sm-6,
		>.col-sm-8,
		>.col-sm-12 {
			float: none;
		}
	}

	hr.style-guide-hr {
		width: 100%;
		margin: 80px 0px;
		border-top: 2px solid #f0f0f4;
	}

	hr.style-guide-bg-gap {
		display: block;
		border: 0 none;
		margin: 50px -50px 0px;
		height: 50px;
		background: #f0f0f4;
		clear:both;
	}

	.style-guide-dark-region {
		display: block;
		border: 0 none;
		margin: 0px -50px 0px;
		padding: 40px 50px;
		background: #2a2a2a;
		clear:both;
	}

	.style-guide-desc {

		margin: 0px -50px 40px;
		padding: 50px 50px;
		border-bottom: 2px solid #f0f0f4;

		.desc-content {
		
			width: 80%;
			max-width: 1200px;
		}

		.topic-group {

			&:not(:last-child){
				padding-bottom: 25px;
				border-bottom: 1px solid #E5E9EA;
			}
		}

		h3 {

			.class {
				font-size: 24px;
				margin-left: 10px;
			}
		}

		p, ul, li {

			@include maison-normal();
			font-size: 16px;
			margin-top: 20px;

			a {

				@include maison-bold();
				color: #7e8183;
				border-bottom: 2px solid rgba(42,42,42,0.12);
				padding-bottom: 0px;

				-webkit-transition: border 140ms ease-out;
					-moz-transition: border 140ms ease-out;
					-o-transition: border 140ms ease-out;
					transition: border 140ms ease-out;

				&:hover {
					border-bottom: 2px solid rgba(42,42,42,0.24);
				}
			}
		}

		u {
			text-decoration: none;
			padding-bottom: 0px;
			border-bottom: 1px solid #cccfd1;
			line-height: 1;
		}

		li {

			&:not(:first-child){
				margin-top: 10px;
				padding-top: 10px;
				border-top: 2px solid #f0f0f4;
			}

			.desc {
				margin-top: 10px;
				padding: 10px 15px;
				background: #f0f0f4;
			}
		}
	}

	.style-guide-region {
		margin: 0;
	}
}

body.style-guide {
	background: #f0f0f4;
}

#layout.style-guide.index .style-guide-desc {

	border-bottom: 0 none;
	margin-bottom: 0px;

	li:not(:first-child) {
		border-top: 0 none;
	}

	p a, 
	ul a, 
	li a {

		@include maison-normal();

		.strong {
			@include maison-bold();
		}
	}

	.topic-group {
		padding-bottom: 50px;
		border-bottom: 1px solid #E5E9EA;
	}
}
