
// globally available shortcut classes & objects

@mixin clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin no-select {    
    -webkit-touch-callout: none;
    -webkit-user-select: none; /* Webkit */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* IE 10  */	
    -o-user-select: none; /* Currently not supported in Opera but will be soon */
    user-select: none;
}

@mixin no-interaction {    
    pointer-events: none;
}

.clearfix { @include clearfix; }
.no-select { @include no-select; }
.no-interaction { @include no-interaction; }

// position

@mixin absolute {
	position: absolute;
	top: 0px;
	left: 0px;
}

@mixin relative {
	position: relative;
	top: 0px;
	left: 0px;
}

@mixin fixed {
	position: fixed;
	top: 0px;
	left: 0px;
}

@mixin inline-block {
	position: relative;
	display: inline-block;
	top: 0px;
	left: 0px;
}

.absolute { position: absolute !important; }
.relative { position: relative !important; }
.fixed { position: fixed !important; }

.inline-block { 
	position:relative !important;
	display: inline-block !important; 
}

// visibility

@mixin hidden {
	visibility: hidden !important;
	opacity: 0;
}

@mixin opacity-0 {
	opacity: 0;
}

@mixin display-none {
	display: none !important;
}

@mixin disabled {
	cursor: default !important;
}

@mixin offscreen {
   position: absolute !important;
   top: -9999px !important;
   left: -9999px !important;
}

.opacity-0 { @include opacity-0; }
.hidden, .invisible { @include hidden; }
.display-none { @include display-none; }
.coming-soon a, .disabled { @include disabled; }
.offscreen { @include offscreen; }

// float

@mixin float-left {
	@include relative;
	float: left;
}

@mixin float-right {
	@include relative;
	float: right;
}

.float-left { @include float-left; }
.float-right { @include float-right; }

// box model

@mixin border-box {

	-webkit-box-sizing: border-box; 
	-moz-box-sizing: border-box; 
	box-sizing: border-box;
}

@mixin content-box {

	-webkit-box-sizing: content-box; 
	-moz-box-sizing: content-box; 
	box-sizing: content-box;
}

.border-box { @include border-box; }
.content-box { @include content-box; }

// centering

@mixin vertical-center {
	
	top: 50%;

	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin vertical-center-container {
	
	-webkit-transform-style: preserve-3d;
	  -moz-transform-style: preserve-3d;
	  transform-style: preserve-3d;
}

.vertical-center { @include vertical-center; }
.vertical-center-container { @include vertical-center-container; }

// horizontal

@mixin horizontal-center {
	
	left: 50%;

	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

@mixin horizontal-center-container {
	
	-webkit-transform-style: preserve-3d;
	  -moz-transform-style: preserve-3d;
	  transform-style: preserve-3d;
}

.horizontal-center { @include horizontal-center; }
.horizontal-center-container { @include horizontal-center-container; }

// both

@mixin both-center {
	
	top: 50%;
	left: 50%;

	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

@mixin both-center-container {
	
	-webkit-transform-style: preserve-3d;
	  -moz-transform-style: preserve-3d;
	  transform-style: preserve-3d;
}

.both-center { @include both-center; }
.both-center-container { @include both-center-container; }

// for abstractview - hides something that isn't alive yet

@mixin uninitialized {
	visibility: hidden;
}

// common buttons

@mixin close-button {
	@include absolute;
}

@mixin home-button {
	@include absolute;
}

.uninitialized { @include uninitialized; }
.close-button { @include close-button; }
.home-button { @include home-button; }

// scroll vs no-scroll

@mixin scroll {
	overflow: auto;
}

@mixin scroll-x {
	overflow-x: auto;
	overflow-y: hidden;
}

@mixin scroll-y {
	overflow-x: hidden;
	overflow-y: auto;
}

@mixin no-scroll {
	overflow: hidden;
}

.scroll { @include scroll; }
.scroll-x { @include scroll-x; }
.scroll-y { @include scroll-y; }
.no-scroll { @include no-scroll; }

@mixin overflow {
	overflow: visible;
}

@mixin overflow-hidden {
	overflow: hidden;
}

.overflow { @include overflow; }
.overflow-hidden { @include overflow-hidden; }

// container filling objects

@mixin fill-cover {

	@include absolute;

	left: 0px;
	right: 0px;
	margin: 0 auto;	

	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	max-width: initial;
	max-height: initial;
	background-repeat: no-repeat;

	&.center-x {
		left: 50%;
		@include horizontal-center;
	}

	&.center-y {
		top: 50%;
		@include vertical-center;
	}

	&.both-center {
		top: 50%;
		left: 50%;
		@include both-center;
	}

	&.center-top-third {
		
		top: 33%;
		left: 50%;

		-webkit-transform: translate(-50%, -33%);
		-ms-transform: translate(-50%, -33%);
		transform: translate(-50%, -33%);
	}

	&.center-bottom-third {
		
		top: 67%;
		left: 50%;

		-webkit-transform: translate(-50%, -67%);
		-ms-transform: translate(-50%, -67%);
		transform: translate(-50%, -67%);
	}
}

.fill-cover { @include fill-cover; }

// fill contain

@mixin fill-contain {

	@include absolute;

	left: 0px;
	right: 0px;
	margin: 0 auto;	

	width: auto;
	height: auto;
	min-width: initial;
	min-height: initial;
	max-width: 100%;
	max-height: 100%;
	background-repeat: no-repeat;

	&.center-x {
		left: 50%;
		@include horizontal-center;
	}

	&.center-y {
		top: 50%;
		@include vertical-center;
	}

	&.both-center {
		top: 50%;
		left: 50%;
		@include both-center;
	}

	&.center-top-third {
		
		top: 33%;
		left: 50%;

		-webkit-transform: translate(-50%, -33%);
		-ms-transform: translate(-50%, -33%);
		transform: translate(-50%, -33%);
	}

	&.center-bottom-third {
		
		top: 67%;
		left: 50%;

		-webkit-transform: translate(-50%, -67%);
		-ms-transform: translate(-50%, -67%);
		transform: translate(-50%, -67%);
	}
}

.fill-contain { @include fill-contain; }

// shortcut for background fill

@mixin bg-fill-cover {

	@include absolute;

	left: 0px;
	right: 0px;
	margin: 0 auto;	

	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	max-width: none;
	max-height: none;

	background-size: cover;
}

.bg-fill-cover { @include bg-fill-cover; }

// svg scaled vs not scaled

figure.scaled {
	
	svg {
		width: 100%;
		height: 100%;
	}
}

// tumblr default buttons

#tumblr_controls {
	display: none !important;
}

// tracking (invisible)

#tracking { display: none; }

// app controllers

#scroll-controller {
	@include relative;
}

#external-component-container {

	@include absolute;
	width: 100%;
}
