
#register-login-overlay {

	h2 {
		margin-top: 12px;
		margin-bottom: 23px;
	}

	p {
		font-size: 13px;
		line-height: 24px;
		margin-bottom: 29px;
		padding: 0 !important;
	}

	a.uppercase {

		position: relative;
		float: left;
		width: 100%;
		font-size: 11px;
		line-height: 1;
		margin: 14px 0 8px 0;
		letter-spacing: 0.06em;
	}

	hr.vertical {
		margin: 0 -10px 0 0;
	}

	.checkbox label {
		text-transform: none;
		letter-spacing: 0.02em;
		font-size: 12px;
		color: #2a2a2a;
	}

	.modal-body {
		padding: 60px 0px 60px 0px !important;
	}

	.col-sm-12 {
		padding: 0 60px;
	}

	@media(min-width: 768px) and (max-width:991px){
		
		.modal-body {
			padding: 60px 0px 60px 0px !important;
		}

		.col-sm-12 {
			padding: 0 50px;
		}
	}
}