
.btn-outline, 
.btn-outline-shadow,
.btn-solid,
.btn-solid-shadow,
.btn-shadow-thumb,
.btn-outline-tile-shadow,
a.btn-outline, 
a.btn-outline-shadow,
a.btn-solid,
a.btn-solid-shadow,
a.btn-shadow-thumb,
a.btn-outline-tile-shadow,
button.btn-outline, 
button.btn-outline-shadow,
button.btn-solid,
button.btn-solid-shadow,
button.btn-shadow-thumb,
button.btn-outline-tile-shadow {

	position: relative;
	color: inherit;
	text-transform: uppercase;
	display: inline-block;
	color: #1e1e1e;

	padding: 0;
	border: 0 none;
	white-space: nowrap;
	letter-spacing: 0.35em;
	line-height: 1;
	opacity: 0;

	font-family: "Maison Neue Bold",Calibri,Arial,Roboto,sans-serif;
	font-weight: bold;
	font-size: 12px;

	-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1);
	-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
	   -moz-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
		 -o-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

	-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
	-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
	   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
		 -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */

	&:hover,
	&:active,
	&:focus {

		color: #1e1e1e;
		background: none;
		border: none;

		-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
	}

	&.white {

		.overlay {
			background: #a9adb0;
		}

		.text-over {
			color: #fdfdfd;
		}
	}

	.title {

		position: relative;
		display: inline-block;
		padding: 0px 0px;
		background: transparent;
		letter-spacing: 0.35em;
		white-space: nowrap;
		z-index: 5;
	}

	.color-block {
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		background: #1e1e1e;
		z-index: 2;
	}

	.overlay {

		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: #fdfdfd;
		opacity: 0;
		z-index: 5;
	}

	.border {

		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: transparent;
		border: 2px solid #d1d4d6;
		z-index: 3;
	}

	.text-over {

		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: transparent;
		opacity: 0;
		z-index: 10;

		color: #1e1e1e;
		font-size: 12px;
		padding: 14px 0px;
		letter-spacing: 0.35em;
		line-height: 1;
	}

	@media(min-width: 1480px){

	}

	@media(min-width: 1200px) and (max-width:1479px){

	}

	@media(min-width: 768px) and (max-width:1199px){
		
		font-size: 11px;
		padding: 0;
		letter-spacing: 0.3em;

		.text-over,
		.title {
			padding: 14px 0px;
			font-size: 11px;
			letter-spacing: 0.3em;
			line-height: 1.3;
		}
	}

	@media(min-width: 768px) and (max-width:991px){
		
		letter-spacing: 0.1em;

		.text-over,
		.title {
			letter-spacing: 0.1em;
		}
	}
}

.btn-outline,
.btn-outline-shadow,
a.btn-outline,
a.btn-outline-shadow,
button.btn-outline,
button.btn-outline-shadow {
	
	&:hover {
		background: transparent;
	}

	&.white {

		color: #fdfdfd;
		border-color: #d1d4d6;

		.title {
			color: #fdfdfd;
		}

		.text-over {
			color: #1e1e1e;
		}

		.overlay {
			background: #fdfdfd;
		}
	}
}

.btn-outline {
	color: #fdfdfd;
}

.btn-outline-shadow,
.btn-solid-shadow,
.btn-shadow-thumb,
.btn-outline-tile-shadow,
a.btn-outline-shadow,
a.btn-solid-shadow,
a.btn-shadow-thumb,
a.btn-outline-tile-shadow,
button.btn-outline-shadow,
button.btn-solid-shadow,
button.btn-shadow-thumb,
button.btn-outline-tile-shadow {

	padding: 0;

	.overlay {
		background: #a9adb0;
	}

	.text-over {
		color: #fdfdfd;
	}

	.shadow {

		position: absolute;
		top: 6px;
		left: 6px;
		width: 100%;
		height: 100%;
		background: rgba(206,207,208,0.7);
		opacity: 0;
		z-index: 0;
	}

	@media(min-width: 768px) and (max-width:991px){
		
		.title {
			padding: 14px 0px;
		}
	}
}

.btn-outline, 
.btn-outline-shadow,
.btn-solid,
.btn-solid-shadow,
a.btn-outline, 
a.btn-outline-shadow,
a.btn-solid,
a.btn-solid-shadow,
button.btn-outline, 
button.btn-outline-shadow,
button.btn-solid,
button.btn-solid-shadow {
	
	height: 50px;
	line-height: 50px;
	padding: 0px;
	
	.text-over,
	.title {
		height: inherit;
		padding: 0px;
		line-height: inherit;
	}

	@media(max-width: 1199px){
		height: 40px;
		line-height: 40px;
	}

	&.fluid {

		width: auto;

		.text-over,
		.title {
			padding: 0px 40px;
		}
	}

	&.short {
		
		height: 40px;
		line-height: 40px;
		padding: 0px;

		&.fluid {

			.text-over,
			.title {
				padding: 0px 20px;
			}
		}

		.shadow {
			top: 4px;
			left: 4px;
		}
	}
}

.btn-solid,
.btn-solid-shadow,
a.btn-solid,
a.btn-solid-shadow,
button.btn-solid,
button.btn-solid-shadow  {

	&:hover,
	&:active,
	&:focus {

		background: #1e1e1e;
	}

	.title {
		width: 100%;
		color: rgba(253,253,253,1);
	}

	.color-block {
		background: #1e1e1e;
	}

	.overlay {
		background: #45ade8;
	}
}

.btn-shadow-thumb,
a.btn-shadow-thumb,
button.btn-shadow-thumb {
	
	padding: 0;

	-webkit-transition: width 200ms cubic-bezier(0.340, 0.065, 0.130, 1), height 200ms cubic-bezier(0.340, 0.065, 0.130, 1), padding 200ms cubic-bezier(0.340, 0.065, 0.130, 1);
	-webkit-transition: width 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005), height 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005), padding 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
	   -moz-transition: width 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005), height 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005), padding 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
		 -o-transition: width 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005), height 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005), padding 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			transition: width 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005), height 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005), padding 200ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

	-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
	-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
	   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
		 -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */

	&:hover {

		//padding: 4px;

		.border {
			border: 2px solid #d1d4d6;
		}
	}

	&.selected {

		//padding: 4px;

		.border {
			border: 2px solid #252627;
		}
	}

	.img-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	img {
		position: relative;
		display: inline-block;
		border: 0 none;
		z-index: 2;
	}

	.bg {

		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: #fdfdfd;
		z-index: 1;
	}

	.border {

		border: 1px solid #d1d4d6;

		-webkit-transition: all 240ms cubic-bezier(0.340, 0.065, 0.130, 1);
		-webkit-transition: all 240ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
		   -moz-transition: all 240ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			 -o-transition: all 240ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				transition: all 240ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

		-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
		-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
		   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			 -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
	}

	.shadow {
		background: rgba(206,207,208,0.7);
		top: 4px;
		left: 4px;
	}

	@media(min-width: 768px) and (max-width:991px){

		.shadow {
			top: 3px;
			left: 3px;
		}
	}
}