
.featured-call-out {

	padding-bottom: 0px !important;
	margin-bottom: 120px;

	.call-out {
		
		margin-bottom: 100px;

		@media(min-width: 1200px) and (max-width:1479px){
			
			h2 {
				font-size: 48px;
				line-height: 1.167;
			}
		}

		@media(min-width: 768px) and (max-width:1199px){

			h2 {
				font-size: 30px;
				line-height: 1.167;
				text-align: left;
			}

			small {
				font-size: 12px;
				margin-top: 17px;
				letter-spacing: 0.16em;
				line-height: 1.3;
				margin-bottom: 9px;
				text-align: left;
			}

			p, ul {
				font-size: 14px;
				line-height: 24px;
			}

			.centered {
				// margin-top: 85px;
				// margin-bottom: 85px;
				margin-left: -10px;
			}
		}
	}

	.details-col {

		&:first-child {
			padding-left: 20px;
		}

		.btn-category-thumb {
			margin-left: -10px;
		}
	}
	
	@media(min-width: 768px) and (max-width:1199px){
		margin-bottom: 60px;
	}
}