
.jumbo-feature {

	position: relative;

	&.contained {

		.centered {
			margin-top: 55px;
			margin-bottom: 85px;
		}

		.no-margin {
			
			.centered {
				margin: 0;
			}
		}

		.call-out {

			height: auto;

			.info {
				
				@include maison-bold();
				font-size: 12px;
				margin-bottom: 17px;
			}

			small {
				margin-top: 34px;
				margin-bottom: 5px;
			}

			p {
				color: #7e8183;
				text-transform: none;
				font-size: 14px;
				line-height: 22px;
				letter-spacing: 0.025em;
			}

			.btn-outline-shadow {
				margin-top: 25px;
			}

			@media(min-width: 768px) and (max-width:1199px){
				padding: 0 30px;
			}
		}
	}

	h2 {
		display: inline-block;
		position: relative;
		left: -2px;
		color: #313132;
	}

	small {
		font-size: 15px;
		margin-top: 19px;
		margin-bottom: 15px;
		color: #1e1e1e;
	}

	p {
		letter-spacing: 0.01em;		
		color: #7e8183;
	}

	.btn-outline,
	.btn-outline-shadow {
		background: #fdfdfd;
	}

	.top {

		display: inline-block;
		position: relative;
		vertical-align: top;
		margin-top: 60px;

		@media(min-width: 768px) and (max-width:1199px){
			margin-top: 0px;
		}
	}

	.centered {
		margin-top: 150px;
		margin-bottom: 185px;
	}

	.media-wrapper {
		
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		z-index: 0;
		padding: 0;

		&.right-align {
			left: auto;
			right: 0px;
		}

		.media {
			
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			z-index: 0;

			background-position: center top;
		}
	}

	.copy {
		position: relative;
		top: 0px;
		left: 0px;
		width: 100%;
		z-index: 2;
	}

	.text-container {
		max-width: 400px;
		z-index: 10;
	}

	.contained .call-out,
	.call-out {

		padding-top: 0px !important;
		height: 100%;
		background: #fdfdfd;

		&:before {
			// display: block;
			// position: absolute;
			// height: inherit;
		}

		ul {

			margin-top: 23px;
			letter-spacing: 0.01em;

			>li {
				margin-bottom: -2px;
			}

			li {
				list-style: none;
				line-height: 24px;
			}

			li:before {
			    content:"•";
			    font-size:18px;
			    vertical-align:middle;
			    line-height:24px;
			    margin-right: 6px;
			}
		}
	}

	@media(min-width: 1200px) and (max-width:1479px){
		
		h2 {
			font-size: 48px;
			line-height: 1.167;
		}
	}

	@media(min-width: 768px) and (max-width:1199px){
		
		h2 {
			font-size: 30px;
			line-height: 1.167;
			text-align: left;
		}

		small {
			font-size: 12px;
			margin-top: 17px;
			letter-spacing: 0.16em;
			line-height: 1.3;
			margin-bottom: 9px;
			text-align: left;
		}

		p, ul {
			font-size: 13px;
			line-height: 24px;
		}

		.centered {
			// margin-top: 85px;
			// margin-bottom: 85px;
			margin-left: -10px;
		}
	}

	@media(min-width: 768px) and (max-width:991px){

		.media-wrapper {

			width: 62.5%;
		}

		.text-container {
			width: 100%;
			margin-left: 0px;
		}

		.col-md-9.col-md-offset-16 {
			margin-left: 62.5%;
			width: 29.16667%;
		}

		.col-md-9.col-md-offset-2 {
			width: 29.16667%;			
		}
	}
}