
.color-select-item {

	&:hover {
		
		.item-cell-badges {
			display: block;
		}
	}

	&.sm {

		.item-cell-color {
			width: 30px;
		}
	}

	// item cells

	.item-cell-details {

		padding: 17px 0px 25px;

		a {
			padding-bottom: 0px;
		}

		.item-cell-info {
			margin-top: 3px;
		}

		@media(min-width: 1200px) and (max-width:1479px){

		}

		@media(min-width: 992px) and (max-width:1199px){
			
			padding: 7px 0px 25px;

			.item-cell-title {
				font-size: 11px;
			}
		}

		@media(min-width: 768px) and (max-width:991px){
			
			padding: 7px 0px 25px;

			.item-cell-title {
				font-size: 11px;
			}
		}
	}
	
	.item-cell-image-wrapper {
		background: #f0f1f3;
	}

	.item-cell-image-link {
		position: relative;
		overflow: hidden;
	}

	.item-cell-image-container {
		
		overflow: hidden;

		.item-cell-image-wrapper {
			
			position: relative;
			overflow: hidden;

			&:not(:first-child){

				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}
		}

		.item-cell-image {
			position: relative;
			display: inline-block;
			width: 100%;
		}

		.overlay {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background: #fdfdfd;
			z-index: 100;
			visibility: hidden;
		}
	}

	.item-cell-hover-top, 
	.item-cell-hover-bottom, 
	.item-cell-image-prev, 
	.item-cell-image-next {
		display: block;
		opacity: 0;
	}

	.item-cell-image-prev,
	.item-cell-image-next {

		@media(min-width: 768px) and (max-width:991px){
			display: none !important;
		}
	}

	.item-cell-hover-bottom {
		
		bottom: -4px;
		height: 20px;

		.item-cell-colors {

			display: inline-block;
			position: relative;
			text-align: center;
		}
	}

	.item-cell-color {

		display: block;
		position: relative;
		float: left;
		top: 5px;
		width: 40px;
		height: 10px;
		vertical-align: top;
		margin-top: 5px;
		z-index: 1;

		&.active {
			margin-top: 0px !important;
			height: 20px !important;
			z-index: 5;
		}

		&:not(:last-child){
			//margin-right: -4px;
		}

		-webkit-transition: margin-top 140ms ease-out, height 140ms ease-out;
			-moz-transition: margin-top 140ms ease-out, height 140ms ease-out;
			-o-transition: margin-top 140ms ease-out, height 140ms ease-out;
			transition: margin-top 140ms ease-out, height 140ms ease-out;

		@media(min-width: 768px) and (max-width:1199px){
			width: 24px;
		}
	}
}