
#shop-the-look-overlay {

	.modal-body {
		padding: 60px 20px 60px 70px !important;
	}

	.left-col {
		padding: 0px;
	}

	.the-look {
		
		padding-right: 35px;

		.item-views-option-tile {
			margin-top: 20px;
			margin-bottom: 0px;
		}
	}

	.left-description {
		
		margin: 29px 0 0 60px;

		p {
			@include maison-bold;
			font-size: 13px;
			color: #2a2a2a;
			text-transform: uppercase;
			letter-spacing: 0.06em;
			margin-top: 0px;
		}

		.list {

			margin-bottom: 30px;

			li {
				margin-bottom: -2px;
				font-size: 13px;
				letter-spacing: 0em;
			}
		}
	}

	.grid-quick-shop {

		.btn-image-label {
			padding: 0;
		}
	}

	@media(min-width: 768px) and (max-width:991px){
		
		.left-description {
			margin: 29px 0 0 40px;
		}
	}
}