.item-views-option-tile-picker {
	
	white-space: nowrap;

	li {

		width: 40px;
		height: 40px;

		a {

			span { 
				
				padding: 0;
				text-align: center;
				cursor: pointer;
				background: none;
				outline: none;
				border: 1px solid #cccfd1;

				-webkit-box-shadow: 2px 2px 0px 0px rgba(49, 49, 50, 0.0);
				-moz-box-shadow:    2px 2px 0px 0px rgba(49, 49, 50, 0.0);
				box-shadow:         2px 2px 0px 0px rgba(49, 49, 50, 0.0);

				-webkit-transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), border-color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				   -moz-transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), border-color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					 -o-transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), border-color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), border-color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

				-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					 -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

				@media(min-width: 768px) and (max-width:991px){
					font-size: 11px;
				}
			}

			&:hover {

				margin-top: -1px;
				margin-bottom: -6px;

				span {

					border: 2px solid #cccfd1;
					background: none;

					-webkit-box-shadow: 4px 4px 0px 0px rgba(49, 49, 50, 0.1);
					-moz-box-shadow: 4px 4px 0px 0px rgba(49, 49, 50, 0.1);
					box-shadow: 4px 4px 0px 0px rgba(49, 49, 50, 0.1);
				}
			}

			&.selected {

				span {

					border: 2px solid #313132;

					-webkit-box-shadow: 2px 2px 0px 0px rgba(49, 49, 50, 0.0);
					-moz-box-shadow:    2px 2px 0px 0px rgba(49, 49, 50, 0.0);
					box-shadow:         2px 2px 0px 0px rgba(49, 49, 50, 0.0);
				}
			}

			&.disabled {

				color: #a9adb0;
				background: url('../../pdp-toolkit/img/content/disabled-wiggle-pattern.png') 0px 0px;
				opacity: 1;

				pointer-events: none;

				span, 
				span:hover {
					border: 1px solid #cccfd1 !important;
					color: #a9adb0 !important;
					background: none;
				}
			}

			&.disabled:hover {

				color: #a9adb0;	

				span, 
				span:hover {
					border: 1px solid #cccfd1 !important;
					color: #a9adb0 !important;
				}
			}
		}
	}
}