
.btn-image-label {

	position: relative;
	left: 0px;
	top: 0px;
	float: left;
	overflow: visible;
	margin-bottom: -5px;

	.image-wrapper {
		position: relative;
		left: 0px;
		top: 0px;
		float: left;
		overflow: hidden;
	}

	.tabs-wrapper {
		position: absolute;
		width: 100%;
		bottom: -15px;
		text-align: center;
	}

	.rollover-tab {

		background-color: #252627;
		display: inline-block;
		float: none;
		height: 30px;
		color: #ffffff;
		font-size: 10px;
		letter-spacing: 0.1em;
		line-height: 30px;
		padding: 0 20px;
		text-transform: uppercase;
		text-align: center;
		opacity: 0;
	}
}

.btn-arrow-label {

	color: #1e1e1e;

	&:hover {
		color: #1e1e1e;
	}

	&.sm {
		.right-link-arrow {
			margin-top: 0px;
			top: 4px;
		}
	}

	.right-link-arrow {

		display: inline-block;
		position: relative;
		overflow: hidden;
		top: 0px;
		margin-top: -2px;
	}
}

a.btn-secondary {
	background: #fdfdfd;
}

.video-block,
.jumbo-video {

	.btn-close {

		position: absolute;
		top: 20px;
		right: 30px;
		width: 40px;
		height: 40px;
		z-index: 100;
		visibility: hidden;

		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		border-radius: 50%;

		background-color: rgba(55,56,58,0.8);
		color: #f9fafb;
		font-size: 26px;

		.icon {
			font-family: "Maison Neue Medium", Calibri, Arial, Roboto, sans-serif;
			font-style: normal;
			text-transform: none;
			font-size: inherit;
		}

		&::before {
			display: none;
			content: '';
		}
	}
}