.community-detail {

	.sidebar-team {
		
		.sidebar-team-container {
			
			.row > div {
				
				margin-bottom: 2px;

				.category-name {
					
					@include maison-bold();
					font-size: 12px;
					letter-spacing: 0.06em;
					padding-top: 13px;
					padding-bottom: 0px;
					white-space: nowrap;

					.arrow {
						top: -2px;
					}
				}
			}
		}
	}
}