
.product-slider {

	opacity: 0;
	background: #fdfdfd;

	.item-details-content-header {
		
		margin-top: 24px;
		left: -3px;

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 8px;
			margin-left: calc(50% - 1px);
			margin-bottom: 0px;
		}

		@media(min-width: 768px) and (max-width:991px){

		}
	}

	.item-details-content-header-title {
		
		display: inline-block;
		position: relative;
		width: 100%;
		max-height: 124px;
		line-height: 62px;
		
		@media(min-width: 1480px){

		}

		@media(min-width: 1200px) and (max-width:1479px){

		}

		@media(min-width: 768px) and (max-width:991px){
			max-height: 80px;
			line-height: 36px;
			font-size: 28px;
		}

		@media(min-width: 768px) and (max-width:991px){

		}

		.line-wrapper {
			overflow: hidden;
		}
	}

	.item-details-price {
		width: auto;
	}

	.item-details-price .item-views-price-lead {
		font-size: 20px;
		
		@media(min-width: 768px) and (max-width:991px){
			font-size: 17px;
		}
	}

	.item-details-rating-header {
		
		width: auto;
		margin-top: 2px;

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 0px;
		}
	}

	.item-details-rating-header {
		white-space: nowrap;
		margin-top: 2px;
		padding-left: 8px;
	}

	.global-views-star-rating i {

		font-size: 18px;
		letter-spacing: 0.1em;

		@media(min-width: 768px) and (max-width:991px){
			font-size: 21px;
		}

		@media(min-width: 768px) and (max-width:991px){

		}
	}

	.global-views-star-rating-review-total {
		
		position: relative;
		display: inline-block;
		margin-left: 5px;
		top: -3px;

		@media(min-width: 768px) and (max-width:991px){
			margin-left: 3px;
			top: -2px;
		}

		@media(min-width: 768px) and (max-width:991px){

		}
	}

	.item-details-image-gallery-view {
		
		position: relative;
		overflow: visible;
		margin-top: 0px;

		@media(min-width: 768px) and (max-width:991px){
			overflow: hidden;
		}
	}

	.item-details-free-shipping {

		margin-top: 6px;

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 1px;
		}

		@media(min-width: 768px) and (max-width:991px){

		}

		@media(max-height: 960px){
			margin-top: 1px;
		}
	}

	.item-details-hr {
		
		margin-top: 23px;
		margin-bottom: 23px;

		@media(max-height: 960px){
			display: none;
		}
	}

	.item-views-option-color {		

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 8px;
			margin-bottom: 20px;
		}

		@media(min-width: 768px) and (max-width:991px){

		}

		@media(max-height: 960px){
			
			margin-top: 16px;

			p {
				margin-bottom: 1px;
			}
		}
	}

	.item-views-option-color-label {
		white-space: nowrap;
		margin-bottom: 7px;
	}

	.item-views-option-color-tiles-container {

		li {
			margin-right: 21px;
		}
	}

	.item-details-pick-up-in-store {
		
		margin-top: 0px;

		button {

			@media(min-width: 768px) and (max-width:991px){
				margin-top: 12px;
			}

			@media(min-width: 768px) and (max-width:991px){

			}
		}
	}

	.item-details-add-to-wishlist {
		
		width: 100%;
		
		button {
			max-width: 340px;
		}
	}

	.product-list-control-button-wishlist {
		
		margin-top: 20px;
		min-height: 45px;

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 15px;
			min-height: 42px;
		}

		@media(min-width: 768px) and (max-width:991px){

		}
	}

	.item-details-add-to-cart button {
		margin-top: 10px;
	}

	.item-details-social-sharing {

		margin-top: 43px;

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 33px;
			padding-bottom: 10px;
		}

		@media(min-width: 768px) and (max-width:991px){

		}
	}

	.item-details-main-content-wrapper {
		width: 100%;
	}

	.item-details-main {

		position: relative;
		z-index: 1;

		@media(min-width: 768px) and (max-width:991px){
			width: 280px;
			padding: 0px;
			margin-right: 10px;
		}
	}

	.item-details-main-bottom-banner {
		margin-bottom: 0px;
	}

	.social-sharing-flyout-icons a {

		i { 

			display: inline-block;
			position: relative;
			top: 5px;
			color: #7e8183; 
			font-size: 21px;
			line-height: 1;

			-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1);
			-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			   -moz-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			     -o-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			        transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
		}

		&:hover {
			i { color: #1e1e1e; }
		}
	}

	.item-details-image-actions {

		// width: 140px;
		// margin: 30px auto 0px;
		// text-align: left;
		
		margin-top: 30px;

		a {

			display: inline-block;
			position: relative;
			color: #7e8183; 
			line-height: 1;

			-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1);
			-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			   -moz-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			     -o-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			        transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
		
			&:hover {
				color: #1e1e1e;
			}
		}

		@media(min-width: 768px) and (max-width:991px){
			
			margin-bottom: 30px;

			a {
				display: block;
				margin-bottom: 5px !important;
			}
		}

		@media(min-width: 768px) and (max-width:991px){

		}
	}

	.item-details-shop-look-link {

		letter-spacing: 0.06em;
		white-space: nowrap;

		-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1);
		-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
		   -moz-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
		     -o-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
		        transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

		-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
		-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
		   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
		     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
		        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */

		.arrow-container {

			display: inline-block;
			position: relative;
			top: 4px;
			overflow: hidden;
			letter-spacing: 0.06em;

			svg { 
				margin-left: 6px;
			}

			svg * {
				fill: #7e8183;
			}
		}
	}

	.item-details-sku-container,
	.item-details-content-header,
	.item-details-info,
	.item-details-main {

		float: right !important;
		max-width: 360px;
		margin-right: 45px;
		
		@media(min-width: 768px) and (max-width:991px){
			width: 280px;
			max-width: none;
			margin-right: 0px;
		}
	}

	.item-details-info {

		@media(max-height: 960px){
			margin-top: -1px;
		}
	}

	.item-details-sku-container {

		margin-top: 0px; //72px - 39px;
		padding-top: 75px;

		@media(min-width: 768px) and (max-width:991px){
			padding-top: 40px;
		}

		@media(max-width: 991px){
			font-size: 11px;
		}

		@media(max-height: 960px){
			
			//margin-top: 20px;

			span {
				display: none;
			}
		}
	}

	.item-details-more-info {

		max-width: 360px;
		margin-right: 45px;
		
		@media(min-width: 768px) and (max-width:991px){
			max-width: none;
			margin-right: 0px;
		}
	}

	.btn-secondary {
		color: #1e1e1e;
	}

	.zoom-viewer-container {

		position: absolute;
		top: 75px;
		left: auto;
		right: 45px;
		opacity: 0;
		max-width: 580px;
		max-height: 580px;
		z-index: 100;
		overflow: visible;

		@media(max-height: 960px){
			top: 20px;
		}

		@media(min-width: 768px) and (max-width:991px){
			right: 10px;
		}

		.shadow {

			position: absolute;
			top: 8px;
			left: 8px;
			width: 100%;
			height: 100%;
			content: "";
			background: rgba(49,49,50, 0.15);
			z-index: 1;

			@media(min-width: 768px) and (max-width:1479px){
				top: 6px;
				left: 6px;
			}
		}

		.zoom-viewer {

			box-sizing: border-box;
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;

			position: relative;
			width: 580px;
			height: 580px;
			overflow: hidden;
			z-index: 2;

			@media(min-width: 992px) and (max-width:1479px){
				width: 480px;
				height: 480px;
			}

			@media(min-width: 768px) and (max-width:991px){
				width: 320px;
				height: 320px;
			}
		}

		.zoomed-image {

			position: absolute;
			max-width: none;
			max-height: none;
		}
	}

	.item-details-slider-controls {
		
		position: absolute;
		padding-top: 75px;

		@media(min-width: 768px) and (max-width:991px){
			padding-top: 40px;
		}

		@media(min-width: 768px) and (max-width:991px){
			margin-left: -5px;
		}

		@media(max-height: 960px){
			//padding-top: 40px;
		}

		.btn-arrow {
			opacity: 0;
		}

		.thumbs-container {

			position: relative;

			.thumbs-slider-wrapper {

				position: relative;
				max-height: 500px;
				padding-bottom: 18px;
				margin-bottom: -18px;
				overflow: hidden;
				
				@media(min-width: 768px) and (max-width:991px){
					max-height: 240px;
				}
			}

			.btn-shadow-thumb .shadow {
				background: rgba(42,42,42,0.12);
			}
		}

	}

	.item-details-slider-controls-list {

		li {
			
			position: relative;
			overflow: visible;
			height: 80px !important;
			display: block !important;

			&:not(:first-child){
				margin-top: 20px;
			}

			.btn-shadow-thumb {

				position: absolute;
				left: 0px;
				right: 0px;
				margin: 0 auto;
				border: 0 none !important;
				width: 80px !important;
				height: 80px !important;

				&:hover {
					//width: 88px !important;
					//height: 88px !important;
				}
			}

			.img-wrapper {
				top: 0px;
				left: 0px;
			}

			img {
				position: relative;
				display: inline-block;
				border: 0 none !important;
				z-index: 2;
				padding: 1px;
			}
		}

		@media(min-width: 768px) and (max-width:991px){
			
			li {
				
				height: 60px !important;

				.btn-shadow-thumb {
					width: 60px !important;
					height: 60px !important;

					&:hover {
						//width: 68px !important;
						//height: 68px !important;
					}
				}

				.img-wrapper {
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;
					overflow: hidden;
				}

				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}

	.item-views-option-color-tiles-container {

		li {
			
			position: relative;
			overflow: visible;
			width: 60px !important;
			height: 80px !important;

			&:not(:first-child){
				margin-top: 20px;
			}

			.item-views-option-color-tile {

				position: absolute;
				left: 0px;
				right: 0px;
				margin: 0 auto;
				border: 0 none !important;
				width: 60px !important;
				height: 80px !important;

				&:hover {
					//width: 68px !important;
					//height: 88px !important;
				}

				.img-wrapper {
					top: 0px;
					left: 0px;

					img {
						width: 100%;
						height: auto;
					}
				}
			}

			img {
				position: relative;
				display: inline-block;
				border: 0 none !important;
				z-index: 2;
				padding: 1px;
			}
		}
	}

	.item-details-image-gallery-wrapper {

		padding-top: 0px;

		@media(min-width: 768px) and (max-width:991px){
			padding-top: 0px;
		}
	}

	.item-details-image-gallery {

		position: absolute;
		top: 0px;
		left: 0px;
		overflow: hidden;
		z-index: 0;

		.overlay {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background: #fdfdfd;
			z-index: 100;
		}
	}

	.zoom-reticule {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 172px;
		height: 172px;
		background: url('../img/magnifier.png') top left no-repeat;
		opacity: 0;
	}

	.item-details-image-gallery-detailed-image {
		
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;

		.image-wrapper {

			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			overflow: hidden;

			img.center-block {

				display: block;
				position: absolute;
				float: none;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0;
			}
		}
	}

	.tooltip {
		
		.tooltip-inner {

			-webkit-box-shadow: none;
			 -moz-box-shadow: none;
			  box-shadow: none;
		}

		.tooltip-arrow {

			top: 26px;
			right: -19px;
			background: transparent;

			-ms-transform: rotate(0deg); /* IE 9 */
			    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
			    transform: rotate(0deg);
		}

		.tooltip-arrow-shadow {

			top: 26px;
			right: -19px;
			background: transparent;

			-ms-transform: rotate(0deg); /* IE 9 */
			    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
			    transform: rotate(0deg);
		}

		.tooltip-inner-shadow {

			display: block;
			position: absolute;
			top: 5px;
			right: -5px;

			background-color: #dbdcdd;
			color: #dbdcdd;
			border: none;
			padding: 20px 40px;

			font-family: "Maison Neue Bold", Calibri, Arial, Roboto, sans-serif;
			font-size: 12px;
			font-style: normal;
			text-transform: uppercase;
			letter-spacing: 0.4em;
			line-height: 14px;
			z-index: 0;
		}
	}

	.item-details-more-info-content-container {

		margin-top: 33px;

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 95px;
		}

		@media(min-width: 768px) and (max-width:991px){

		}
	}

	.item-details-more-info-content-tabs {

		position: relative;
		text-align: justify;
		height: 37px;

		.item-details-tab-title {

			position: relative;
			display: inline-block;
			float: none;
			top: 0px;
			left: 0px;
			padding: 0;
			margin: 0;
			width: auto;

			a {

				-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1);
				-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				   -moz-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				     -o-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				        transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

				-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
				-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
			}

			&:hover a {
				color: #313132;
			}
		}

		.break {
			position: relative;
			display: inline-block;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 0px;
		}
	}

	.item-details-tab-content {
		
		padding-top: 23px;
		height: 350px;

		p {
			letter-spacing: 0.01em;
			margin-bottom: 22px;
		}

		@media(min-width: 1200px){
			overflow: auto;
		}

		@media(min-width: 768px) and (max-width:991px){
			
			padding-top: 0px;
			margin-top: -9px;
			margin-bottom: 30px;
			height: auto;

			p {
				margin-bottom: 17px;
			}

			li {
				letter-spacing: 0.01em;
				margin-bottom: 0px;
			}
		}

		@media(min-width: 768px) and (max-width:991px){

		}
	}

	.item-details-tabs-wrapper {

		@media(min-width: 768px) and (max-width:991px){
			padding-bottom: 55px;
		}
	}

	.item-details-tabs-titles {

		@media(min-width: 768px) and (max-width:991px){
			
			.subtitle {

				font-size: 12px;
				margin-top: 27px;
				letter-spacing: 0.1em;
				margin-bottom: 17px;
				line-height: 1.4em;
			}

			.item-details-hr {
				margin-bottom: 18px;
			}
		}

		@media(min-width: 768px) and (max-width:991px){

		}
	}

	.item-details-tab-main-title {

		@media(min-width: 768px) and (max-width:991px){
			line-height: 1;
			margin-bottom: 2px;
			text-transform: uppercase;
		}

		@media(min-width: 768px) and (max-width:991px){

		}
	}

	.item-details-tabs-content {

		@media(min-width: 768px) and (max-width:991px){
			left: 3.67%;
		}
	}

	.item-details-options-content {
		overflow: visible;
	}

	.btn-outline-shadow,
	.btn-outline-tile-shadow {
		background: rgba(240,240,242,0.6);
	}

	&.contained {

		.btn-outline-shadow,
		.btn-outline-tile-shadow {
			background: rgba(253,253,253,0.6);
		}

		.item-details-image-gallery-wrapper {

			padding-top: 75px;

			@media(min-width: 768px) and (max-width:991px){
				padding-top: 40px;
			}
		}
	}

}