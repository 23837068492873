
.shop-more {
	
	display: inline-block;
	margin-top: 0px;
	margin-bottom: 88px;
	text-align: center;
	width: 100%;
	float: none;

	@media(min-width: 992px) and (max-width:1199px){

	}

	@media(min-width: 768px) and (max-width:991px){
		margin-top: 25px;
	}

	.fancy-title {

		margin-bottom: 104px;

		@media(min-width: 992px) and (max-width:1199px){
			margin-bottom: 75px;
		}

		@media(min-width: 768px) and (max-width:991px){
			margin-bottom: 75px;			
		}
	}

	.shop-more-content {
		
		display: inline-block;
		margin-top: 0px;
		margin-bottom: 0px;
		float: none;
		width: 100%;
		white-space: nowrap;
		
		@media(min-width: 768px) and (max-width:991px){
			margin-top: 0px;
			margin-left: 0px;
		}

		.row {
			display: inline-block;
			margin-top: 7px;
			white-space: nowrap;
		}
	}

	.item-details-shop-more-content {
		text-align: center;
	}
	
	@media(min-width: 768px) and (max-width:991px){

		.featured-link {
			
			width: 185px;
			height: 100px;
			overflow: hidden;

			&:nth-child(3n+4){
				clear:both;
			}
		}
	}

}

.featured-link {

	position: relative;
	display: inline-block;
	overflow: hidden;
	padding: 0;
	margin: 0 0 10px;
	border: 5px solid #ffffff;
	width: auto;
	height: auto;

	a {

		position: absolute;
		width: 100%;
		height: 100%;
		text-transform: uppercase;
		color: #ffffff;
		font-size: 11px;
		top: 0px;
		left: 0px;
		text-align: center;
		letter-spacing: 0.1em;
		line-height: 110px;
		padding-top: 2px;

		&:hover {
			color: #fff;
		}

		span {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			text-transform: uppercase;
			color: #ffffff;
			font-size: 11px;
			font-family: "Maison Neue Demi", Calibri, Arial, Roboto, sans-serif;
			text-align: center;
			letter-spacing: 0.1em;
		}

		.underline {
			position: absolute;
			top: calc(50% + 8px);
			left: 0px;
			right: 0px;
			height: 1px;
			background: #fdfdfd;
			opacity: 0;
		}
	}
	
	img {
		width: 100%;
		height: auto;
		max-width: none;
	}	

	@media(min-width: 1200px){
		float: none;
	}

	@media(max-width: 1199px){
		float: left;
	}

	@media(min-width: 1200px) and (max-width:1479px){
		
		margin: 0;
		width: 185px;

		a {
			line-height: 86px;
		}
	}

	@media(min-width: 768px) and (max-width:1199px){
		
		&:nth-child(3n+4){
			clear: both;
		}

		height: 100px;
		width: auto;

		a {
			line-height: 80px;
		}
	}
}