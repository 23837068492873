.team-detail {

	.team-detail-info section {
		margin-bottom: 28px;
		padding-bottom: 60px;
	}

	.team-detail-info section {
		
		h3 {
			line-height: 1;
			margin-bottom: 27px;
		}
	}
}