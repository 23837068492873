
.sales-landing-content {

	.featured-call-out {

		.call-out {
			
			small {
				margin-bottom: 7px;
			}

			p {
				font-size: 14px;
				line-height: 20px;
				letter-spacing: 0.01em;
				margin-bottom: 5px;
			}

			@media(min-width: 768px) and (max-width:1199px){

				h2 {
					font-size: 36px;
					line-height: 42px;
					margin-bottom: 0px;		
				}

				small {
					line-height: 22px;
					margin-top: 13px;
					margin-bottom: 9px;
				}
			}
		}

		.featured-product {
			margin-bottom: 42px;
		}
	}
}