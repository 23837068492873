
/* fonts */

@mixin maison-normal() {
	font-family: "Maison Neue Book",Calibri,Arial,Roboto,sans-serif;
	font-weight: normal;
}

@mixin maison-medium() {
	font-family: "Maison Neue Medium",Calibri,Arial,Roboto,sans-serif;
	font-weight: 500;
}

@mixin maison-demi() {
	font-family: "Maison Neue Demi",Calibri,Arial,Roboto,sans-serif;
	font-weight: 600;
}

@mixin maison-bold() {
	font-family: "Maison Neue Bold",Calibri,Arial,Roboto,sans-serif;
	font-weight: 700;
}