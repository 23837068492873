
.gender-featured-categories-wrapper {
		
}

.gender-featured-categories {

	padding-bottom: 20px;
	margin-bottom: -12px;

	// left col

	h1 {
		letter-spacing: -0.006em;
		text-transform: none;
	}

	h2 {

		margin-top: 36px;
		letter-spacing: 0.32em;
	}

	p.gender-featured-categories-description {
		margin-top: 9px;
		
		@media(min-width: 768px) and (max-width:991px){
			font-size: 12px;
			line-height: 19px;
		}
	}

	a.gender-featured-categories-link {
		
		margin-top: 24px;

		@media(min-width: 768px) and (max-width:991px){
			font-size: 11px;
		}
	}

	// media queries

	@media(min-width: 992px) and (max-width:1199px){
		
		margin-top: 78px;

		h1 {
			font-size: 48px;
			line-height: 1;
		}

		h2 {
			font-size: 14px;
			margin-top: 24px;
		}

		p.gender-featured-categories-description {
			font-size: 13px;
			line-height: 19px;
			margin-top: 13px;
		}

	}

	@media(min-width: 768px) and (max-width:991px){
		margin-top: 76px;
	}

	// deeper nested elements
	
	.gender-featured-categories-right-column,
	.gender-featured-categories-left-cell-top,
	.gender-featured-categories-right-row-top,
	.gender-featured-categories-right-bottom-cell-left, 
	.gender-featured-categories-right-row-bottom {
		overflow: visible;
	}

	.gender-featured-categories-left-cell-top { 

		@media(min-width: 768px) and (max-width:991px){
			min-height: 305px;
			margin-left: 0px;
			width: 100%;
		}
	}

	.gender-featured-categories-left-cell-mid {

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 57px;
		}
	}

	.gender-featured-categories-right-row-bottom {

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 62px;
		}
	}

	// right col

	@media (min-width: 1480px){
		
		.gender-featured-categories-left-cell-mid {
			margin-top: 67px;
		}
	}

	//

	.gender-featured-categories-left-column {

		@media(min-width: 1200px) and (max-width:1479px){
			width: calc(33.333%);
		}

		@media(min-width: 992px) and (max-width:1199px){
			width: calc(33.333%);
		}

		@media(min-width: 768px) and (max-width:991px){
			width: calc(33.333%);
		}
	}

	.gender-featured-categories-right-column {

		@media(min-width: 1200px) and (max-width:1479px){
			width: calc(66.667% - 71px);
			margin-left: 70px;
		}

		@media(min-width: 992px) and (max-width:1199px){
			width: calc(66.667% - 41px);
			margin-left: 40px;
		}

		@media(min-width: 768px) and (max-width:991px){
			width: calc(66.667% - 41px);
			margin-left: 40px;
		}
	}
}

.btn-gender-featured-category {

	position: relative;
	float: left;
	overflow: visible;
	margin-bottom: -5px;

	.gender-featured-category-name {
		
		margin-bottom: 0px;
		padding-bottom: 16px; 
		white-space: nowrap;
		width: 100%;

		.arrow {

			position: relative;
			display: inline-block;
			top: -1px;
			width: 29px;
			height: 5px;
			margin-left: 7px;
			overflow: hidden;
			visibility: hidden;

			svg {
				position: absolute;
				width: 100%;
				height: 100%;
			}
		}
	}

	.gender-featured-category-thumb {
		overflow: hidden;
		max-width: 100%;
	}

	.tabs-wrapper {
		position: absolute;
		width: 100%;
		bottom: -15px;
		text-align: center;
	}

	.rollover-tab {

		background-color: #252627;
		display: inline-block;
		float: none;
		height: 30px;
		margin-left: 8px;
		color: #ffffff;
		font-size: 10px;
		letter-spacing: 0.1em;
		line-height: 30px;
		padding: 0 20px;
		text-transform: uppercase;
		text-align: center;
		opacity: 0;
		vertical-align: bottom;

		&:first-child {
			margin-left: 0px;
		}
	}
}
