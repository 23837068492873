
.gender-masonry-wrapper {
	background: transparent;
}

.masonry-cell {

	overflow: hidden;

	span {
		display: inline-block;
	}

	&:hover {

		.masonry-overlay {
			display: block;
		}
	}

	&.masonry-cell-instagram {

		.masonry-overlay-title {
			
			margin-top: 25px;

			font-size: 28px;
			line-height: 36px * 1.2;

			@media(min-width: 1200px) and (max-width:1479px){
				font-size: 24px;
				line-height: 32px * 1.2;
			}

			@media(min-width: 992px) and (max-width:1199px){
				font-size: 24px;
				line-height: 32px * 1.2;
				margin-top: 18px;
			}

			@media(min-width: 768px) and (max-width:991px){
				font-size: 18px;
				line-height: 24px * 1.2;
				margin-top: 12px;
			}
		}

		.masonry-overlay-link {

			margin-top: 28px;
			letter-spacing: 0.32em;

			@media(min-width: 1200px) and (max-width:1479px){

			}

			@media(min-width: 992px) and (max-width:1199px){
				margin-top: 16px;
			}

			@media(min-width: 768px) and (max-width:991px){
				margin-top: 14px;
			}
		}
	}

	.masonry-overlay {
		
		display: block;
		opacity: 0;
		background: rgba(30,30,30,0.85);

		.masonry-overlay-title {

			position: relative;
			top: 0px;
			left: 0px;
			width: 100%;
			margin-top: 23px;

			font-size: 44px;
			line-height: 52px * 1.2;

			.line-wrapper {
				width: 112% !important;
				overflow: hidden;
				white-space: nowrap;
			}

			@media(min-width: 1200px) and (max-width:1479px){
				font-size: 36px;
				line-height: 40px * 1.2;
				margin-top: 24px;
			}

			@media(min-width: 992px) and (max-width:1199px){
				font-size: 28px;
				line-height: 32px * 1.2;
				margin-top: 18px;
			}

			@media(min-width: 768px) and (max-width:991px){
				font-size: 22px;
				line-height: 26px * 1.2;
				margin-top: 2px;
			}
		}

		.masonry-overlay-link {

			margin-top: 26px;
			letter-spacing: 0.32em;
			overflow: hidden;

			@media(min-width: 1200px) and (max-width:1479px){

			}

			@media(min-width: 992px) and (max-width:1199px){
				margin-top: 16px;
				font-size: 12px;
			}

			@media(min-width: 768px) and (max-width:991px){
				margin-top: 4px;
				font-size: 10px;
			}
		}

		.masonry-overlay-link-line {			
			position: relative;
			top: -1px;
			border: 0 none;
			height: 2px;
			background: #fff;
		}
	}
}


