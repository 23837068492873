
.grid-quick-shop {

	.btn-image-label {
		
		padding: 0 25px;

		.image-wrapper {
			
			img {
				width: 100%;
			}
		}

		.tabs-wrapper {

			width: auto;
			left: 25px;
			right: 25px;
		}
	}

	.details {

		.attribute-selector-container {
			margin-top: 15px;
		}

		.item-views-option-tile-picker {
			
			margin-bottom: -2px;
			
			@media(min-width: 768px) and (max-width:991px){
				margin-bottom: -2px;
			}
		}

		.item-views-option-tile {

			margin-top: 20px;
			
			@media(min-width: 768px) and (max-width:991px){
				margin-top: 20px;
			}
		}

		.btn-outline-shadow,
		.btn-solid-shadow {
			
			margin-top: 20px;

			@media(min-width: 768px) and (max-width:991px){

				line-height: 12px;

				.title,
				.text-over {
					padding: 15px 0px !important;
					font-size: 11px;
				}
			}
		}
	}
}