.standard-slider {

	position: relative;
	opacity: 0;

	h3 {
		
		font-family: "Maison Neue Bold", Calibri, Arial, Roboto, sans-serif;
		font-style: normal;
		font-size: 16px;
		text-transform: uppercase;
		color: #1e1e1e;
		letter-spacing: 0.1em;
		margin-bottom: 25px;
	}

	.controls {

		position: absolute;
		width: 100%;
		top: 50%;
		margin-top: -5px;
		z-index: 100;

		.btn-arrow {

			position: absolute;
			width: 30px;
			height: 10px;
			visibility: visible;

			&.left-arrow {
				left: -15px;
			}

			&.right-arrow {
				right: -15px;
			}

			svg {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}
		}
	}

	.count { 
		
		position: absolute;
		top: 14px;
		right: 0px;
		color: #7e8183;
		font-size: 11px;
		letter-spacing: 0.06em;
		text-transform: uppercase;
		overflow: hidden;

		@include maison-demi();

		span {
			display: inline-block;
			position: relative;
		}
	}

	.slides {

		position: relative;
		top: 0px;
		width: 100%;
		height: auto;
		z-index: 1;
		overflow: hidden;

		.slider-item-link {

			position: absolute;
			visibility: visible;
			top: 0px;
			width: 100%;
			height: auto;

			&:not(:first-child){

				position: absolute;
				width: 100%;
				height: auto;
				visibility: hidden;
			}

			.slider-item-image {

				position: relative;
				top: 0px;
				left: 0px;
				width: 100%;
				height: auto;
			}
		}

	} // .gender-slides

	.btn-arrow-label {

		padding: 20px 0px 0 0;
		color: #37383a;
		float: right;
		font-family: "Maison Neue Bold", Calibri, Arial, Roboto, sans-serif;
		font-size: 21px;
		font-style: normal;

		.arrow {
			
			position: relative;
			display: inline-block;
			width: 25px;
			height: 5px;
			top: 12px;
			overflow: hidden;
			padding-left: 0px;
			margin-left: 16px;
			line-height: 16px;
			vertical-align: top;

			svg {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
			}
		}
	}
}