
#main.navigation {
	padding-bottom: 0px;
}

#site-header {

	.header-secondary-wrapper {

		.header-menu-secondary-nav {
		
			.navigation-link {

				>a {

					position: relative;
					display: inline-block;
					padding-bottom: 2px;
					border-bottom: 2px solid rgba(228,229,230,0);
							
					-webkit-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1);
					-webkit-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
					   -moz-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
					     -o-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
					        transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

					-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
					-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
					   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
					     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
					        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */

					&:hover {
						border-bottom: 2px solid rgba(228,229,230,1);
					}
				}

				&.selected >a {
					border-bottom: 2px solid rgba(228,229,230,1);
				}
			}

			.navigation {

				overflow-x: hidden;
				overflow-y: hidden;
				overflow: hidden;

				background: transparent !important;
				border: 0 none;
				padding: 0px;

				-webkit-box-shadow: none;
				 -moz-box-shadow: none;
				  box-shadow: none;

				//

				>.container {
					
					width: 100%;
					max-width: 1840px;
					margin: 0 auto;
					padding: 0 40px;

					>.row {
						position: relative;
					}
				}

				.btn-close {
					position: absolute;
					float: none;
					display: block;
					top: -4px;
					right: 0px;
					z-index: 100;
				}

				.quick-links {

					padding-top: 24px;
					text-align: left;

					a {
						
						border-bottom: 1px solid rgba(42,42,42,0);

						&:hover {
							border-bottom: 1px solid rgba(42,42,42,0.12);
						}
					}

					h3 {
						margin-left: -2px;
						white-space: normal;
					}

					ul {
						margin: 0;
					}

					li {
						margin-top: 19px;
					}

					.hr {
						margin: 13px 0 35px;
					}

					@media(min-width: 1480px){

					}

					@media(min-width: 992px) and (max-width: 1479px){
						
						padding-top: 0px;

						li {
							margin-top: 16px;
						}

						h3 {
							margin-bottom: 22px;
						}

						.hr {
							margin: 23px 0 25px;
						}
					}

					@media(min-width: 768px) and (max-width: 991px){

					}
				}

				// responsive

				figure.featured-image {

				 	position: absolute;
				 	top: 0px;
				 	left: 0px;
				 	z-index: 0;
				 	width: 100%;
				 	height: 100%;
				 	background-repeat: no-repeat;
				 	background-position-y: 60px;

					@media(min-width: 1480px){

					}

					@media(min-width: 1200px) and (max-width: 1479px){
						display: none;
					}

					@media(min-width: 992px) and (max-width: 1199px){	
						display: none;
					}

					@media(min-width: 768px) and (max-width: 991px){
						display: none;
					}
				}

				.subcategories {

					text-align: left;
					margin-top: 43px;
					
					.hr {
						margin: 13px 0 10px;
					}

					li {

						margin-bottom: 0px;
						line-height: 30px;

						a {

							@include maison-demi();
							font-size: 12px;
							color: #7e8183;
							line-height: 1.2;
							letter-spacing: 0.02em;
						}
					}

					@media(min-width: 1480px){

					}

					@media(min-width: 992px) and (max-width: 1479px){

						margin-top: 43px - 24px;

						li {
							line-height: 26px;

							a {
								font-size: 12px;
							}
						}

						.subcategory-col {
							margin-left: 4.3333%;
						}

						.subcategory {
							margin-bottom: 35px;
						}
					}

					@media(min-width: 768px) and (max-width: 991px){

					}
				}

				@media(min-width: 1480px){

				}

				@media(min-width: 992px) and (max-width: 1479px){

					.subcategory-col {
						margin-left: 4.3333%;
					}
				}

				@media(min-width: 768px) and (max-width: 991px){

				}

				//

				.cta {
				 	
				 	text-align: left;
				 	padding: 49px 60px 50px;

				 	p {
				 		line-height: 1;
				 		margin-bottom: 3px;
				 	}

				 	a {
				 		margin-top: 24px;
				 	}

					.btn-solid-shadow.btn-small {

						@include maison-bold();
						padding: 0 19px !important;
						line-height: 30px !important;
						height: 30px;

						.title, .text-over {
							font-size: 11px;
							letter-spacing: 0.1em;
						}
					}

					@media(min-width: 768px) and (max-width: 1479px){
				 		padding: 39px 50px 40px;
				 		display: none;
					}
				}

				//

				a {

					-webkit-transition: border 420ms cubic-bezier(0.190, 0.020, 0.065, 1.000); 
					   -moz-transition: border 420ms cubic-bezier(0.190, 0.020, 0.065, 1.000); 
					     -o-transition: border 420ms cubic-bezier(0.190, 0.020, 0.065, 1.000); 
					        transition: border 420ms cubic-bezier(0.190, 0.020, 0.065, 1.000); /* custom */

					-webkit-transition-timing-function: cubic-bezier(0.190, 0.020, 0.065, 1.000); 
					   -moz-transition-timing-function: cubic-bezier(0.190, 0.020, 0.065, 1.000); 
					     -o-transition-timing-function: cubic-bezier(0.190, 0.020, 0.065, 1.000); 
					        transition-timing-function: cubic-bezier(0.190, 0.020, 0.065, 1.000); /* custom */
				}

				hr {
				 	position: relative;
				 	width: 100%;
				 	height: 1px;
				 	background: rgba(42,42,42,0.12);
				 	border: 0 none;
				 	margin: 31px 0 33px;
				}

				.hr {

					background: rgba(42,42,42,0.12);
					border: 0 none;
				}

				//

				&.list {

					.subcategories {

						.subcategory {

							a {
								
								border-bottom: 1px solid rgba(42,42,42,0);

								&:hover {
									border-bottom: 1px solid rgba(42,42,42,0.12);
								}
							}

							h4 a {

								&:hover {
									border-bottom: 1px solid rgba(42,42,42,0.0);
								}								
							}
						}
					}
				}

				&.showcase,
				&.thumbnails {

					.subcategories {

						.subcategory {

							margin-bottom: 54px;

							a {
								width: 80%;
								border-bottom: 0 none;
								white-space: normal;
								
								&:hover {

									border-bottom: 0 none;

									span {
										border-bottom: 1px solid rgba(42,42,42,0.12);
									}
								}

								span {
									
									border-bottom: 1px solid rgba(42,42,42,0);
											
									-webkit-transition: border 360ms cubic-bezier(0.340, 0.065, 0.130, 1);
									-webkit-transition: border 360ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
									   -moz-transition: border 360ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
									     -o-transition: border 360ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
									        transition: border 360ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

									-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
									-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
									   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
									     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
									        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
								}
							}
						}

						.thumb {
							
							margin-top: 20px;
							padding-right: 12.667%;

							span {
								margin-top: 13px;
							}

							a {
								
								display: block;
								@include maison-demi();
								color: #7e8183;
								font-size: 12px;
								letter-spacing: 0.01em;
								margin-top: 15px;
							}
						}
					}
				}

				&.list,
				&.thumbnails {

					.quick-links,
					.cta {

						@media(min-width: 1200px) and (max-width: 1479px){
							margin-left: 0px;
							width: 100%;
						}

						@media(min-width: 992px) and (max-width: 1199px){	
							margin-left: 0px;
							width: 100%;
						}

						@media(min-width: 768px) and (max-width: 991px){
							margin-left: 0px;
							width: 100%;
						}
					}

					.featured-col {

						@media(min-width: 1480px){
							min-width: 600px;
						}

						@media(min-width: 1200px) and (max-width: 1479px){
							width: 21%;
						}

						@media(min-width: 992px) and (max-width: 1199px){
							width: 21%;	
						}

						@media(min-width: 768px) and (max-width: 991px){
							width: 21%;
						}
					}

					.subcategory-col {

						@media(min-width: 1480px){
							margin-left: 45px;
						}

						@media(min-width: 1200px) and (max-width: 1479px){
							width: 66.667%;
						}

						@media(min-width: 992px) and (max-width: 1199px){
							width: 66.667%;	
						}

						@media(min-width: 768px) and (max-width: 991px){
							width: 66.667%;
						}

					}
				}

				&.showcase {

					.subcategory-col {

						@media(min-width: 992px) and (max-width: 1479px){
							margin-left: 3.3333%;
						}

						@media(min-width: 768px) and (max-width: 991px){

						}
					}

					.subcategories {

						@media(min-width: 992px) and (max-width: 1479px){
							
							.subcategory {
								
								.hr {
									margin: 8px 0 10px;
								}

								.btn-category-thumb {
									margin-top: 10px;
									padding-right: 0px;
								}
							}
						}

						@media(min-width: 768px) and (max-width: 991px){
							width: 100%;
						}
					}
				}
			}
		}
	}

	.site-search {
		
		border-bottom: 1px solid #e4e6e6;

		.search-results-container {
			overflow-y: visible;
			overflow: visible;
			background: transparent;
			border-top: 1px solid #e4e6e6;
			margin-top: 30px;
		}

		.results {
			padding-bottom: 43px;

			.item-cell:nth-child(4n+1){

				@media(min-width: 768px) and (max-width: 1199px){
					clear: both;
				}
			}
		}
	}

	.site-search-content,
	.search-no-results-wrapper,
	.search-results-wrapper {

		position: relative;
		z-index: 10;
	}

	.search-no-results {
		
		.search-results-content-container {
			padding-bottom: 79px;
		}
	}
}

.layout-container:not(.header-compact){

	.header-secondary-wrapper {

		@media(min-width: 992px){
			padding: 28px 0px 29px;
		}

		@media(min-width: 768px) and (max-width: 991px){

		}
	}
}

.layout-container:not(.header-compact){

	#site-header:not(.solid){

		.header-secondary-wrapper {
	
			@media(min-width: 992px){
				height: 180px;
				background: url('../img/nav-gradient.png') repeat-x center top;
			}
		}
	}
}

.nav-open #site-header,
#site-header.solid {

	.site-search {
		border-bottom: 0 none;
		height: 99px;
	}
}

.white-block {

	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 0px;
	background: #fdfdfd;
	z-index: 1;

	-webkit-transition: height 360ms cubic-bezier(0.270, 0, 0.260, 0.990); /* older webkit */
	-webkit-transition: height 360ms cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	   -moz-transition: height 360ms cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	     -o-transition: height 360ms cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	        transition: height 360ms cubic-bezier(0.270, -0.010, 0.260, 0.990); /* custom */

	-webkit-transition-timing-function: cubic-bezier(0.270, 0, 0.260, 0.990); /* older webkit */
	-webkit-transition-timing-function: cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	   -moz-transition-timing-function: cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	     -o-transition-timing-function: cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	        transition-timing-function: cubic-bezier(0.270, -0.010, 0.260, 0.990); /* custom */
}

.nav-veil {

	position: absolute;
	top: 65px;
	left: 0px;
	width: 100%;
	height: 0px;
	background: rgba(240, 240, 244, 0.8);
	z-index: 0;

	pointer-events: none;

	-webkit-transition: opacity 600ms cubic-bezier(0.270, 0, 0.260, 0.990); /* older webkit */
	-webkit-transition: opacity 600ms cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	   -moz-transition: opacity 600ms cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	     -o-transition: opacity 600ms cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	        transition: opacity 600ms cubic-bezier(0.270, -0.010, 0.260, 0.990); /* custom */

	-webkit-transition-timing-function: cubic-bezier(0.270, 0, 0.260, 0.990); /* older webkit */
	-webkit-transition-timing-function: cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	   -moz-transition-timing-function: cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	     -o-transition-timing-function: cubic-bezier(0.270, -0.010, 0.260, 0.990); 
	        transition-timing-function: cubic-bezier(0.270, -0.010, 0.260, 0.990); /* custom */
}

.header-compact {
	
	&.search-open {

		.site-search {
			&::after {
				opacity:0;
			}
		}
	}

	#site-header .header-secondary-wrapper .header-menu-secondary-nav .navigation hr {
		margin: 11px 0 33px;
	}

	&:not(.nav-open):not(.search-open) .site-search {

		&::after {

			position: absolute;
			left: 0px;
			right: 0px;
			width: auto;
			height: 5px;
			bottom: -5px;
			background: rgba(0,0,0,0.1);
			content: "";
			opacity: 1;
			z-index: 1;

			-webkit-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
			   -moz-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
			     -o-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
			        transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

			-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
			   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
			     -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
			        transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */
		}
	}

	.site-search-content {
		top: 19px;
	}

	.white-block {
		top: 60px;
	}
}

.header-site-search {
	z-index: 10;
}

.header-secondary-container {
	position: relative;
	z-index: 10;
}

.open .header-menu-level1-anchor:before, 
.open .header-menu-home-anchor:before, 
.open .header-menu-shop-anchor:before {
	display: none !important;
}