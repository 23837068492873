
.team-picks {
	
	margin-top: 83px;

	@media(min-width: 1200px) and (max-width:1479px){

	}

	@media(min-width: 992px) and (max-width:1199px){
		margin-top: 30px;
	}

	@media(min-width: 768px) and (max-width:991px){
		margin-top: 30px;
	}

	.team-picks-content {

		@media(min-width: 1200px) and (max-width:1479px){

		}

		@media(min-width: 992px) and (max-width:1199px){
			margin-top: 68px;
		}

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 68px;
		}

		.team-picks-athletes {

			@media(min-width: 1200px) and (max-width:1479px){
				width: calc(50%);
			}

			@media(min-width: 992px) and (max-width:1199px){
				width: calc(50%);
			}

			@media(min-width: 768px) and (max-width:991px){

			}
		
			.team-picks-athletes-list {

				@media(min-width: 768px) and (max-width:991px){
					margin-left: 32px;
				}
			}
		}

		.team-picks-athlete-products {

			@media(min-width: 1200px) and (max-width:1479px){
				width: calc(50% - 21px);
				margin-left: 20px;
			}

			@media(min-width: 992px) and (max-width:1199px){
				width: calc(50% - 21px);
				margin-left: 20px;
			}

			.item-cell {

				&:first-child {

					@media(min-width: 1200px) and (max-width:1479px){
						width: calc(50% - 10px);
						margin-left: 0px;
					}

					@media(min-width: 992px) and (max-width:1199px){
						width: calc(50% - 10px);
						margin-left: 0px;
					}
				}

				&:not(:first-child){

					@media(min-width: 1200px) and (max-width:1479px){
						width: calc(50% - 11px);
						margin-left: 20px;
					}

					@media(min-width: 992px) and (max-width:1199px){
						width: calc(50% - 11px);
						margin-left: 20px;
					}
				}

				&:nth-child(odd){

					padding-top: 0px;
					margin-top: 150px;

					@media(min-width: 1200px) and (max-width:1479px){

					}

					@media(min-width: 992px) and (max-width:1199px){
						margin-top: 90px;
					}
				}
			}
		} 

		.team-picks-athlete-link {
			
			position: relative;
			float: left;
			margin-top: -7px;
			margin-right: 6px;
			margin-left: -2px;
			white-space: nowrap;

			.arrow {
				margin-left: 10px;
				overflow: hidden;
			}

			svg {
				display: inline-block;
				position: relative;
				overflow: hidden;
			}
		}

		.btn-athlete-portrait {
			
			position: relative;
			float: left;

			.images-wrapper {
				
				position: relative;
				float: left;
				overflow: hidden;
			}

			.team-picks-athlete-image-button {
				position: relative;
				float: left;
				top: 0px;
				left: 0px;
				width: 100%;
				background: #f0f1f3;
				overflow: hidden;
			}

			.overlay {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				background: #fdfdfd;
				z-index: 100;
				visibility: hidden;
			}

			.tabs-wrapper {
				position: absolute;
				width: 100%;
				bottom: -15px;
				text-align: center;
			}

			.rollover-tab {

				background-color: #252627;
				display: inline-block;
				float: none;
				height: 30px;
				margin-left: 8px;
				color: #ffffff;
				font-size: 10px;
				letter-spacing: 0.1em;
				line-height: 30px;
				padding: 0 20px;
				text-transform: uppercase;
				text-align: center;
				opacity: 0;
			}
		}

		.team-picks-list-athlete {

			margin-top: 13px;

			-webkit-transition: color 400ms cubic-bezier(0.190, 0, 0.450, 0.975); /* older webkit */
			-webkit-transition: color 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
			   -moz-transition: color 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
			     -o-transition: color 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
			        transition: color 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); /* custom */

			-webkit-transition-timing-function: cubic-bezier(0.190, 0, 0.450, 0.975); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
			   -moz-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
			     -o-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
			        transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); /* custom */

	        a {
	        	-webkit-transition: none;
	        	-webkit-transition: none;
	        	   -moz-transition: none;
	        	     -o-transition: none;
	        	        transition: none;
	        }
		}

		.team-picks-athlete-name {
			
			color: #1e1e1e;
			overflow: hidden;
			line-height: 64px;
			margin-top: -2px;
			z-index: 100;

			.athlete-name-inner {
				display: inline-block;
			}
		}
	}
}