
.item-details-product-review-content {

	margin-top: 0px;
	margin-bottom: 73px;

	.fancy-title {
		margin-bottom: 72px;
	}

	.item-details-product-reviews-center {
		padding-bottom: 0px;
		overflow: visible;
	}	

	.product-reviews-center-list {
		
		position: relative;
		float: left;
		text-align: center;
		overflow: visible;

		@media(min-width: 768px) and (max-width:991px){
			margin: 33px -20px 0;
		}

		.product-reviews-center-review-container {
			
			position: relative;
			float: left;
		}

		.product-reviews-review {

			text-align: left;

			@media(min-width: 768px) and (max-width:991px){
				width: 50%;
				padding: 0 4.1667%;
				margin: 0;
			}

			&:nth-child(2n+2){ 

				@media(min-width: 768px) and (max-width:991px){
					
				}
			}
		}

		figure.vert-line {

			position: absolute;
			top: 80px;
			bottom: 45px;
			width: 1px;
			left: 50%;
			background: #cccfd1;
		}

		.btn-outline-shadow {
			position: relative;
			display: inline-block;
			background: transparent;
			margin-top: 52px;
		}
	}

	.product-reviews-center-rating-value {
		line-height: 1;
	}

	.product-reviews-review-comment-footer-button {
		
		-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1);
		-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
		   -moz-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
		     -o-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
		        transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

		-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
		-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
		   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
		     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
		        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
	}

	.global-views-star-rating-area-empty-content, 
	.global-views-star-rating-area-filled {
		white-space: nowrap;
	}

	.product-reviews-review .global-views-star-rating-empty, 
	.product-reviews-review .global-views-star-rating-filled {
		letter-spacing: 0.26em;
		font-size: 34px;
		margin-bottom: 6px;
	}

	.product-reviews-review-comment-footer {
		margin-top: 34px;
	}

	.product-reviews-review-attribute {

		@media(min-width: 768px) and (max-width:991px){
			
			position: relative;
			display: block;
			width: 100%;
			font-size: 12px;
			white-space: nowrap;

			&:not(:first-child){
				margin-top: 5px;
			}

			h2 {
				line-height: 1.12;
			}
		}
	}
}