
.most-popular {

	margin-top: 0px;
	margin-bottom: 30px;
		
	@media(min-width: 992px) and (max-width:1199px){
		margin-bottom: 20px;
	}

	@media(min-width: 768px) and (max-width:991px){
		margin-bottom: 20px;		
	}

	.most-popular-content {
		
		margin-top: 106px;
		
		@media(min-width: 992px) and (max-width:1199px){
			margin-top: 86px;
		}

		@media(min-width: 768px) and (max-width:991px){
			margin-top: 86px;
		}
	}

	.item-cell-image-link {
		overflow: hidden;

		.item-cell-image {
			position: relative;
			display: inline-block;
		}
	}
}