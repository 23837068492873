
.shop-the-look {

	margin-top: 0px;

	.quick-shop-row {

		margin-top: 0px;
		margin-bottom: 76px;
		padding-top: 32px;

		@media(min-width: 768px){
			width: 100% !important;
			max-width: 1440px;
		}

		.btn-outline-shadow {

			padding: 0px;

			.title,
			.text-over {
				padding-left: 0px !important;
				padding-right: 0px !important;
			}
		}

		.details {


			@media(min-width:768px) and (max-width: 1200px){

				padding: 0;

				&:not(.cols-3){
					width: 46.67%;
				}
			}
		}
	}
}