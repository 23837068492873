.header-secondary-wrapper.community {
	
}

.community-filters {

	ul li {

		&:hover {
			color: #a9adb0;
		}

		&.selected a {

			border-bottom: 2px solid rgba(55,56,58,1);
		}

		a {

			border-bottom: 2px solid rgba(55,56,58,0);

			-webkit-transition: border 400ms cubic-bezier(0.290, 0.000, 0.000, 1); /* older webkit */
			-webkit-transition: border 400ms cubic-bezier(0.290, 0.000, 0.000, 1.005); 
			   -moz-transition: border 400ms cubic-bezier(0.290, 0.000, 0.000, 1.005); 
			     -o-transition: border 400ms cubic-bezier(0.290, 0.000, 0.000, 1.005); 
			        transition: border 400ms cubic-bezier(0.290, 0.000, 0.000, 1.005); /* custom */

			-webkit-transition-timing-function: cubic-bezier(0.290, 0.000, 0.000, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.290, 0.000, 0.000, 1.005); 
			   -moz-transition-timing-function: cubic-bezier(0.290, 0.000, 0.000, 1.005); 
			     -o-transition-timing-function: cubic-bezier(0.290, 0.000, 0.000, 1.005); 
			        transition-timing-function: cubic-bezier(0.290, 0.000, 0.000, 1.005); /* custom */

			&:hover {
				color: #2a2a2a;
			}
		}
	}
}