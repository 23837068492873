
.attribute-selector-container .attribute-selector,
.attribute-selector {

	padding: 9px 20px 7px;
	background: #fdfdfd !important;
	height: 40px;

	-webkit-box-shadow: 2px 2px 0px 0px rgba(49, 49, 50, 0.0);
	-moz-box-shadow:    2px 2px 0px 0px rgba(49, 49, 50, 0.0);
	box-shadow:         2px 2px 0px 0px rgba(49, 49, 50, 0.0);

	-webkit-transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), height 160ms ease-out; 
	   -moz-transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), height 160ms ease-out; 
	     -o-transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), height 160ms ease-out; 
	        transition: box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000), height 160ms ease-out; /* custom */

	-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
	   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
	     -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
	        transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

	ul {
		padding-bottom: 10px;
	}

	&.expanded {
		height: auto;
	}

	&:hover,
	&.expanded,
	&:hover.expanded {

		padding: 8px 19px 6px;
		border-width: 2px;
		
		-webkit-box-shadow: 4px 4px 0px 0px rgba(49, 49, 50, 0.1);
		-moz-box-shadow: 4px 4px 0px 0px rgba(49, 49, 50, 0.1);
		box-shadow: 4px 4px 0px 0px rgba(49, 49, 50, 0.1);
	}

	ul {

		li {

			position: relative;

			-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1);
			-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			   -moz-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			     -o-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			        transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */

			hr {

				position: absolute;
				bottom: 0px;
				left: 0px;
				width: 100%;
				height: 1px;
				background: #cccfd1;
				opacity: 0;
			}

			&:hover,
			&.selected {
				border-bottom: none;
			}

			&.selected {
				color: #313132;
				background: transparent;
				padding: 0px;
				margin: 0 20px;
			}
		}
	}
}