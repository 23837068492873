.gender-block {

	position: relative;
	z-index: 1;
	overflow: hidden;
	opacity: 0;

	a.home-gender-image {
		overflow: hidden;
	}

	.overlay {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: #1e1e1e;
		z-index: 100;
		visibility: hidden;
	}

	.text-content {
		
		h2 {
			position: relative;
			text-transform: none;

			.line-wrapper {
				overflow: hidden;
				width: 100% !important;
			}

			.line {
				width: 100% !important;
			}

			@media(min-width: 1480px){
				font-size: 120px;
			}

			@media(min-width: 1200px) and (max-width:1479px){
				font-size: 100px !important;
			}

			@media(min-width: 992px) and (max-width:1199px){
				font-size: 80px !important;
			}

			@media(min-width: 768px) and (max-width:991px){

			}
		}
	}

	.home-gender-button {

		margin-top: 25px !important;

		@media(min-width: 1480px){

		}

		@media(min-width: 1200px) and (max-width:1479px){
			margin-top: 18px !important;
		}

		@media(min-width: 992px) and (max-width:1199px){
			margin-top: 2px !important;
		}

		@media(min-width: 768px) and (max-width:991px){
			margin-top: -5px !important;
		}
	}

}