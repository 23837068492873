
.jumbo-video-modal {

	.close {
		top: 20px !important;
		right: 0px !important;
	}

	.modal-content {
		background: rgba(42,42,42,0.2);
		border: 0 none;
	}
}