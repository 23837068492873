
.stories-block {

	padding-bottom: 32px;
	
	.gender-community-secondary-content-wrapper {

		@media(min-width: 768px) and (max-width:1199px){
			margin-left: 0px;
			padding-left: 40px;
			width: 50%;
		}
	}

	.gender-community-secondary-content {

		@media(min-width: 1200px) and (max-width:1479px){
			margin-top: 40px;
		}

		@media(min-width: 992px) and (max-width:1199px){
			margin-top: 0px;
		}

		h2 {

			position: relative;
			font-size: 60px;
			line-height: 64px;
			letter-spacing: -0.006em;
			margin-left: -3px;

			@media(min-width: 1200px) and (max-width:1479px){
				font-size: 40px;
				line-height: 42px;
			}

			@media(min-width: 992px) and (max-width:1199px){
				font-size: 40px;
				line-height: 42px;
			}

			@media(min-width: 768px) and (max-width:991px){
				font-size: 36px;
				line-height: 38px;
			}
		}

		p.gender-community-subheader {

			margin-top: 41px;
			letter-spacing: 0.32em;
			font-size: 15px;
			line-height: 1;

			@media(min-width: 1200px) and (max-width:1479px){
				margin-top: 31px;
			}

			@media(min-width: 992px) and (max-width:1199px){
				margin-top: 26px;
				letter-spacing: 0.22em;
				font-size: 15px;
			}

			@media(min-width: 768px) and (max-width:991px){
				margin-top: 26px;
				letter-spacing: 0.22em;
				font-size: 15px;
			}
		}

		p.gender-community-secondary-description {

			margin-top: 12px;

			@media(min-width: 1200px) and (max-width:1479px){
				margin-top: 12px;
			}

			@media(min-width: 992px) and (max-width:1199px){
				margin-top: 12px;		
				font-size: 13px;
				line-height: 18px;		
			}

			@media(min-width: 768px) and (max-width:991px){
				margin-top: 12px;		
				font-size: 13px;
				line-height: 18px;		
			}
		}

		a.btn-outline-shadow {

			margin-top: 25px;

			@media(min-width: 1200px) and (max-width:1479px){
				margin-top: 25px;
			}

			@media(min-width: 992px) and (max-width:1199px){
				margin-top: 18px;
			}

			@media(min-width: 768px) and (max-width:991px){
				margin-top: 27px;
			}
		}

		a.gender-community-secondary-team-link {
			
			color: #b3b7ba;

			@media(min-width: 1200px) and (max-width:1479px){
				margin-top: 27px;
			}

			@media(min-width: 992px) and (max-width:1199px){
				margin-top: 27px;				
			}

			@media(min-width: 768px) and (max-width:991px){
				margin-top: 12px;
			}
		}
	}

	.gender-community-secondary-image-wrapper {
		
		position: relative;
		float: left;
		overflow: hidden;
		margin-left: 60px;
		width: 50%;

		img {
			margin-left: 0px;
		}

		@media(min-width: 1480px){

		}

		@media(min-width: 1200px) and (max-width:1479px){

		}

		@media(min-width: 768px) and (max-width:1199px){
			margin-left: 0px;
		}
	}
}