
.btn-collection-thumb {

	overflow: visible;
	margin-bottom: -5px;

	&.grid-thumb {
		margin-bottom: 32px;
	}

	&.bottom-center-label {
		margin-bottom: 55px;
	}

	a {
		display: inline-block;
		position: relative;
	}

	.arrow {
		position: relative;
		display: inline-block;
		top: -1px;
		width: 29px;
		height: 5px;
		margin-left: 7px;
		overflow: hidden;
		visibility: hidden;
	}

	.category-name {

		text-transform: uppercase;
		font-size: 11px;
		color: #1e1e1e;
		display: block;
		float: left;
		clear: both;
		letter-spacing: 0.02em;
		margin-bottom: 0px;
		padding-bottom: 16px;
		font-family: "Maison Neue Demi",Calibri,Arial,Roboto,sans-serif;
		font-weight: 600;

		svg {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}

	.category-thumb, 
	.img-wrapper {

		display: inline-block;
		position: relative;
		overflow: hidden;
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.overlay-content {

		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		display: block;
		opacity: 0;
		background: rgba(30,30,30,0.85);
		z-index: 2;

		.overlay-title {

			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			margin-top: 0px;

			font-size: 44px;
			line-height: 52px * 1.2;
			text-align: center;

			font-family: "Maison Neue Bold",Calibri,Arial,Roboto,sans-serif;
			font-weight: bold;
			color: #fff;

			.line-wrapper {
				overflow: hidden;
				white-space: nowrap;
				width: 100% !important;
			}

			.line {
				left: 0px;
				right: 0px;
				margin: 0 auto;
			}

			@media(min-width: 1200px) and (max-width:1479px){
				font-size: 36px;
				line-height: 40px * 1.2;
				margin-top: 0px;
			}

			@media(min-width: 992px) and (max-width:1199px){
				font-size: 30px;
				line-height: 32px * 1.2;
				margin-top: 0px;
			}

			@media(min-width: 768px) and (max-width:991px){
				font-size: 26px;
				line-height: 26px * 1.2;
				margin-top: 0px;
			}
		}
	}

	.tabs-wrapper {
		position: absolute;
		width: 100%;
		bottom: -6px;
		text-align: center;
		z-index: 10;
	}

	.rollover-tab {

		background-color: #252627;
		display: inline-block;
		float: none;
		height: 30px;
		color: #ffffff;
		font-size: 10px;
		letter-spacing: 0.1em;
		line-height: 30px;
		padding: 0 20px;
		text-transform: uppercase;
		text-align: center;
		opacity: 0;

		&:not(:first-child){
			margin-left: 8px;
		}
	}
}

