
.side-widgets {

	padding: 0px;

	.side-widget {
		margin-bottom: 0px;
	}

	.social-links {

		padding-bottom: 27px;

		>h4 {
			margin-bottom: 14px;
		}

		.content {
			
			a i {
				
				font-size: 20px;

				-webkit-transition: color 400ms cubic-bezier(0.340, 0.065, 0.130, 1);
				-webkit-transition: color 400ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				   -moz-transition: color 400ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				     -o-transition: color 400ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				        transition: color 400ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

				-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
				-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
			}
		}
	}

	.twitter,
	.instagram {

		padding-bottom: 25px;

		h4 {

			margin-bottom: 18px;

			i {
				
				display: inline-block;
				font-weight: normal;
				font-size: 13px;
				margin-top: -3px;
				margin-right: 5px;

				&.bd-instagram:before {
					content: 'I';
				}
			}
		}

		p {
			letter-spacing: 0em;
		}

		.content {
			overflow: hidden;
		}
	}

	.soundcloud {
		padding-bottom: 25px;
	}

	.spotify {
		padding-bottom: 26px;
	}

	.twitter {

		h4 {
			margin-bottom: 20px;
		}
	}

	.events {
		
		padding-bottom: 25px;

		h4 {
			margin-bottom: 5px;
		}
	}
}