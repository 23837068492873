
.facets-categories-container {
	position: absolute;
}

.facets-categories {

	top: 0px;
	width: 100%;

	.inner-container {

		&.bottom {
			position: absolute;
			width: 100%;
			bottom: 0px;
		}
	}

	.facets-category-group {

		margin-bottom: 48px;

		h4 {

			margin-bottom: -7px;

			.icons i {
				position: relative;
				top: -1px;
				margin-right: 3px;
				font-size: 12px;
			}
		}

		>ul li {

			font-size: 0.8em;

			ul li {
				font-size: 1em;
			}
		}

		li {

			margin-top: 16px;

			a {

				-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1);
				-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				   -moz-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
					 -o-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
						transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

				-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
				-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005);
				   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005);
					 -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005);
						transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
			}
		}
	}

	@media(max-width: 1199px){

		left: -30px;
		z-index: 1000;

		&.fixed{
			left: 0px;
		}

		.inner-container {
			position: relative;
			top: -5px;
			background: #fdfdfd;
		}

		.nav-tabs {

			background: #fff;
			border-bottom: 1px solid #f0f1f3;

			li {
				margin-top: -3px;

				button {

					height: 50px;
					line-height: 50px;
					font-size: 12px;
					letter-spacing: 0.3em;

					.title {
						color: #1e1e1e;
					}

					.title, .text-over {
						font-size: inherit;
						letter-spacing: inherit;
					}

					.text-over {
						color: #fff;
					}
				}

				&.active {
					button .title {
						color: #fff;
					}
				}
			}
		}

		.facets-categories-controls {

			border-bottom: none;

			li button {

				display: block;
				height: 46px;
				font-family: "Maison Neue Bold", Calibri, Arial, Roboto, sans-serif;
				font-size: 12px;
				font-style: normal;
				line-height: 46px;
				letter-spacing: 0.4em;
				text-transform: uppercase;
				color: #1e1e1e;
			}
		}

		.facets-category-group {

			margin-bottom: 0px;
			border-top: none;

			&:first-child {
				margin-top: 0px;
			}

			&:not(:first-child){

				h4 {
					border-top: 1px solid #414142;
				}
			}

			h4 {

				font-size: 16px;
				margin-bottom: 0px;
				margin-top: 0px;
				background-color: #1e1e1e;
				color: #fff;

				&.collapsed {

					.icons .bd-cross {
						opacity: 0;
					}
				}

				.icons i {

					color: #7e8183;
					font-size: 26px;
					line-height: 78px;
					vertical-align: middle;
					font-weight: normal;

					-webkit-transition: all 320ms cubic-bezier(0.725, 0.005, 0.345, 0.885);
					   -moz-transition: all 320ms cubic-bezier(0.725, 0.005, 0.345, 0.885);
					     -o-transition: all 320ms cubic-bezier(0.725, 0.005, 0.345, 0.885);
					        transition: all 320ms cubic-bezier(0.725, 0.005, 0.345, 0.885); /* custom */

					-webkit-transition-timing-function: cubic-bezier(0.725, 0.005, 0.345, 0.885);
					   -moz-transition-timing-function: cubic-bezier(0.725, 0.005, 0.345, 0.885);
					     -o-transition-timing-function: cubic-bezier(0.725, 0.005, 0.345, 0.885);
					        transition-timing-function: cubic-bezier(0.725, 0.005, 0.345, 0.885); /* custom */

					&.bd-cross {

						color: #45ade8;
						font-size: 36px;
						line-height: 72px;
						margin-right: -15px;

						&::before {
							@include maison-medium();
							content: '×';
						}
					}

					&.bd-gt {
						font-size: 27px;
						margin-right: -4px;
					}

					&.bd-menu1 {
						font-size: 40px;
						margin-right: -9px;
					}
				}
			}

			.collapse,
			.collapsing {

				li {
					position: relative;
					font-size: 12px;
					line-height: 62px;
					height: 60px;
					margin-top: 0px;
					border-top: none;
					padding: 0 30px;

					&:first-child {
						
						label {
							border-top: none;

						}
					}

					label {
						position: relative;
						width: 100%;
						font-size: inherit;
						line-height: inherit;
						height: 100%;
						vertical-align: middle;
						color: #2a2a2a;
						border-top: 1px solid #cccfd1;
						padding: 0 50px;
					}

					input {
						position: absolute;
						display: block;
					}

					input[type=checkbox]:checked+label i {
						
						background: #2a2a2a;
						border: 1px solid #2a2a2a;

						&::before {
							position: absolute;
							top: 3px;
							left: 3px;
							content: 'D';

							font-size: 14px;
							font-family: 'billabong_dingsregular';
							font-weight: normal;
							font-style: normal;
							color: #fff;
						}
					}

					i {
						position: absolute;
						top: 20px;
						left: 10px;
						width: 20px;
						height: 20px;
						border: 1px solid #cccfd1;
					}
				}
			}
		}

		.tab-content {

			border-bottom: none;

			.tab-pane.in {
				border-bottom: 1px solid #f0f1f3;
				padding-bottom: 60px;
			}
		}

		#facets-tabs-sort {

			padding-bottom: 0px;
			
			.facets-categories-controls {
				border-bottom: 1px solid #f0f1f3;
			}

			.facets-category-group {

				position: relative;
				line-height: 82px;
				height: 80px;
				margin-top: 0px;
				border-top: none;
				padding: 0 30px;
				width: 100%;

				label {

					position: relative;
					width: 100%;
					font-size: inherit;
					line-height: inherit;
					height: 100%;
					vertical-align: middle;
					color: #2a2a2a;
					padding: 0 50px;
					background-color: #f9f9fa;
					letter-spacing: 0.16em;
					text-align: center;

					font-size: 16px;
					margin-bottom: 0px;
					margin-top: 0px;

				}

				&:not(:first-child) label {
					border-top: 1px solid #cccfd1;
				}

				input {
					position: absolute;
					display: block;
				}

				&.checked {
					label {
						background-color: #1e1e1e;
						color: #fff;
					}
				}

				// input[type=radio]:checked+label {
				// 	background-color: #1e1e1e;
				// 	color: #fff;
				// }
			}
		}
	}
}

.button-tabs {

	.facets-categories {

		.nav-tabs li {

			button, 
			button.border {

				-webkit-transition: all 160ms cubic-bezier(0.725, 0.005, 0.345, 0.885);
				   -moz-transition: all 160ms cubic-bezier(0.725, 0.005, 0.345, 0.885);
				     -o-transition: all 160ms cubic-bezier(0.725, 0.005, 0.345, 0.885);
				        transition: all 160ms cubic-bezier(0.725, 0.005, 0.345, 0.885); /* custom */

				-webkit-transition-timing-function: cubic-bezier(0.725, 0.005, 0.345, 0.885);
				   -moz-transition-timing-function: cubic-bezier(0.725, 0.005, 0.345, 0.885);
				     -o-transition-timing-function: cubic-bezier(0.725, 0.005, 0.345, 0.885);
				        transition-timing-function: cubic-bezier(0.725, 0.005, 0.345, 0.885); /* custom */				
			}

			&.active button {

				color: #fff;
				background: #1e1e1e;

				.border {
					border-color: #1e1e1e;
				}
			}
		}
	}
}

.facets:not(.category-landing){

	.facets-categories-container {

		@media(max-width: 1199px){
			display: none;
		}
	}

	.facets-categories-content {

		@media(max-width: 1199px){
			margin-left: 0;
			width: 100%;

			.first-feature {
				border-top: 0 none;
			}
		}
	}
}

.facets {

	.facets-categories-content {

		@media(max-width: 1199px){

			.feature {

				padding: 40px 0px;

				&.first-feature {
					border-top: 0 none;
				}

				.jumbo-feature .call-out {
				    padding: 0px 60px;
				}
			}
		}
	}
}
