
.view-changer {
	
	position: relative;
	overflow: hidden;

	.changer-view {
		
		position: absolute;
		top: 0px;
		left: 0px;
		visibility: hidden;

		&.active {
			position: relative;
			visibility: visible;
		}

		&.transitioning {
			visibility: visible;
		}
	}
}