
.slider-container-wrapper {
	background: transparent;
}

.jumbo-slider-detailed {

	position: relative;
	margin-top: -40px;

	// XL - currently only on gender page

	&.xl {

		.slides-texts .slide-text .slide-content {

			h2 {
				margin-left: -7px;
				line-height: 120px;
			    font-size: 100px;
			}

			@media(min-width: 1200px) and (max-width:1479px){

				width: 1110px;
				max-width: none;
				padding-left: 60px;

				h2 {
					font-size: 82px;
					line-height: 98px;
				}

				p {
					font-size: 17px;
				}
			}

			@media(min-width: 992px) and (max-width:1199px){

				width: 970px;
				max-width: none;
				padding-left: 3.5%;
				margin: 0 auto;

				h2 {
					font-size: 48px;
					line-height: 48px*1.2;
				}

				p {
					font-size: 14px;
					margin-top: 15px;
					margin-bottom: 2px;
				}
			}

			@media(min-width: 768px) and (max-width:991px){

				width: 702px;
				max-width: none;
				margin: 0 auto;

				h2 {
					font-size: 40px;
					line-height: 40px*1.2;
				}

				p {
					font-size: 14px;
					margin-top: 15px;
					margin-bottom: 2px;
				}
			}
		}
	}

	.slider-container {

		position: relative;
		top: 0px;
		width: 100%;
		overflow: hidden;
		max-width: 1920px;
	}

	.slider-controls {

		position: absolute;
		width: 100%;
		top: 0px;
		z-index: 50;

		@media(min-width: 768px) and (max-width:991px){
			top: 10px;
		}

		.arrows {

			position: absolute;
			width: 100%;

			.slider-arrow {

				position: absolute;
				display: block;
				top: 0px;
				width: initial;
				height: initial;
				visibility: visible;

				&.slider-arrow-prev {
					left: 0px; 
				}

				&.slider-arrow-next {
					right: 0px;
				}

				button {
					padding: 30px;
					background: transparent;
				}

				svg {

					position: relative;
					width: 42px;
					height: 10px;
					overflow: visible;

					* {
						fill: #fdfdfd !important;
					}
				}
			}
		}

		.pagination {

			position: absolute;
			left: 0px;
			right: 0px;
			height: 0px;
			text-align: center;

			.pagination-item {

				position: relative;
				display: inline-block;
				width: 7px;
				height: 7px;
				background: transparent;
				opacity: 0.5;
				padding: 15px;

				cursor: pointer;

				-webkit-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				   -moz-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				     -o-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				        transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

				-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				     -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				        transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

				&:not(:last-child){
					margin-right: 5px;
				}

				.slider-pager-icon {

					position: absolute;
					top: 15px - 5px;
					left: 15px - 3px;
					width: 6px;
					height: 6px;
					border: 4px solid #fff;

					-webkit-border-radius: 7px;
					-moz-border-radius: 7px;
					border-radius: 7px;

					-webkit-transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					   -moz-transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					     -o-transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					        transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

					-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					     -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					        transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */
				}

				.slash {

					position: absolute;
					visibility: hidden;
					top: 13px;
					left: -13px;
					width: 56px;
					height: 2px;
					background: #fff;
				}

				&:hover {
					opacity: 1 !important;
				}

				&.selected {
					
					opacity: 1 !important;

					.slider-pager-icon {
					
						top: 15px - 11px;
						left: 15px - 10px;
						width: 20px;
						height: 20px;
						border: 2px solid #fff;		

						-webkit-border-radius: 20px;
						-moz-border-radius: 20px;
						border-radius: 20px;
					}			
				}

				@media(min-width: 768px) and (max-width:1199px){
					
					padding: 15px 10px;
					
					.slider-pager-icon {
						left: 15px - 3px - 5px;
					}

					.slash {
						left: -17px;
					}

					&.selected .slider-pager-icon {
						left: 15px - 10px - 5px;
					}
				}
			}
		}
	}

	.slides {

		position: relative;
		top: 0px;
		width: 100%;
		height: auto;
		z-index: 1;
		overflow: hidden;

		.slide {

			position: relative;
			visibility: visible;
			top: 0px;
			width: 100%;
			height: auto;
			opacity: 0;

			&:not(:first-child){

				position: absolute;
				width: 100%;
				height: auto;
				visibility: hidden;
			}

			.slide-image {

				position: relative;
				top: 0px;
				left: 0px;
				width: 100%;
				height: auto;
			}
		}

	} // .slides

	.slides-texts {

		position: absolute;
		top: 0px;
		width: 100%;
		z-index: 20;

		.slide-text {

			position: absolute;
			width: 100%;
			visibility: visible;
			opacity: 0;

			&:not(:first-child) {
				visibility: hidden;
			}

			&.centered {
				
				text-align: center;

				.slide-content {
					margin-left: 0px;
					width: 100%;
				}

				.line,
				.line-wrapper {
					width: 100% !important;
				}
			}

			&.video-content {

				.slide-content {
					
					color: #ffffff;
					text-align: left;
					font-family: "Maison Neue Book",Calibri,Arial,Roboto,sans-serif;

					h2 {

						position: relative;
						letter-spacing: 0.006em;
						font-size: 60px;
						line-height: 77px;
						margin-left: -4px;

						.line-wrapper {
							width: 100% !important;
							overflow: hidden;
						}

						.line {
							width: 100% !important;
							white-space: nowrap;
						}
					}

					p {
						@include maison-bold();
						letter-spacing: 0.3em;
						margin-top: 31px;
						margin-bottom: 31px;
						line-height: 1;
						overflow: hidden;
					    
					    color: inherit;
					    text-transform: uppercase;
					    font-size: 15px;

						span {
							display: inline-block;
							position: relative;
						}
					}

					.play-icon {

						display: inline-block;
					    font-family: FontAwesome;
					    font-style: normal;
					    color: #fff;

					    text-rendering: auto;
					    -webkit-font-smoothing: antialiased;
					    -moz-osx-font-smoothing: grayscale;

						&:before {
							content: "\f04b";
						}
					}

					@media(min-width: 768px) and (max-width:1199px){

						h2 {
							font-size: 48px;
							line-height: 48px*1.2;
						}

						p {
							font-size: 14px;
							margin-top: 15px;
							margin-bottom: 2px;
						}
					}
				}
			}

			.slide-content {

				position: relative;
				top: 0px;
				left: 0px;
				right: 0px;
				margin: 0px auto;
				color: #fff;
				max-width: initial;

				h2 {
					position: relative;
					font-family: "Maison Neue Bold",Calibri,Arial,Roboto,sans-serif;
					font-weight: bold;
					letter-spacing: -0.006em;
					margin-left: -7px;
				    font-size: 80px;
					line-height: 85px * 1.2;
					color: inherit;
				    text-transform: none;
				    
				}

				p {
					position: relative;
					@include maison-bold();
					letter-spacing: 0.3em;
					margin-top: 30px;
					margin-bottom: 22px;
					line-height: 1;
					color: inherit;
					text-transform: uppercase;
					font-size: 18px;
					overflow: hidden;

					span {
						display: inline-block;
						position: relative;
					}
				}

				a {
					position: relative;
					letter-spacing: 0.35em;
					border-color: #d1d4d6;
					color: inherit;
					text-transform: uppercase;
					display: inline-block;
					font-size: 12px;
					margin-top: 15px;
					font-family: "Maison Neue Bold",Calibri,Arial,Roboto,sans-serif;
					font-weight: bold;
				}

				.line-wrapper {
					overflow: hidden;
				}

				//

				@media(min-width: 1480px){

					width: 1310px;
					max-width: initial;
				}

				@media(min-width: 1200px) and (max-width:1479px){

					width: 100%;
					margin-left: 43px;
					max-width: initial;

					h2 {
				   		font-size: 58px;
						line-height: 58px * 1.2;
					}

					p {
						margin-top: 20px;
						margin-bottom: 12px;
						font-size: 16px;
					}
				}

				@media(min-width: 992px) and (max-width:1199px){

					width: 100%;
					margin-left: 33px;
					max-width: initial;

					h2 {
						font-size: 42px;
						line-height: 42px*1.2;
					}

					p {
						font-size: 14px;
						margin-top: 18px;
						margin-bottom: 2px;
					}

					.btn-outline {
						max-width: 280px;
					}
				}

				@media(min-width: 768px) and (max-width:991px){

					width: 100%;
					margin-left: 33px;
					max-width: initial;

					h2 {
						font-size: 42px;
						line-height: 42px*1.2;
					}

					p {
						font-size: 14px;
						margin-top: 18px;
						margin-bottom: 2px;
					}

					.btn-outline {
						max-width: 240px;
					}
				}
			}
		}
	} // .slides-texts

	.slider-shadows {

		position: absolute;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 2;

		@media(min-width: 768px) and (max-width:1199px){
			display: none;
		}

		.shadow {

			position: absolute;
			top: 0px;
			width: 356px;
			height: 100%;

			&.shadow-left {

				display: none;
				left: 0px;
				background: url('../img/slider-control-gradient-left.png') repeat-y left top;
				opacity: 0;
			}

			&.shadow-right {

				display: none;
				right: 0px;
				background: url('../img/slider-control-gradient-right.png') repeat-y right top;
				opacity: 0;
			}
		}
	}
}