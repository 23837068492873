
.product-pairing {

	position: relative;
	padding-bottom: 0px;
	margin: 0px auto 71px;
	
	&:not(.contained){

		@media(min-width: 1480px){
			width: 1420px;
		}

		@media(min-width: 1200px) and (max-width:1479px){
			width: 1170px;
		}

		@media(min-width: 992px) and (max-width:1199px){
			width: 970px;
		}

		@media(min-width: 768px) and (max-width:991px){
			width: 720px;
			margin-top: 25px;
			margin-bottom: 40px
		}
	}

	&.vertical-slider {
		
		.carousel .title {
			padding: 0 0 0 5px;
		}
	}

	&.contained {
		
		overflow: hidden;
	}

	.fancy-title {
		margin-bottom: 0px;
	}

	.filters {

		position: relative;
		float: left;
		width: 100%;
		z-index: 100;

		&::before, 
		&::after {
			display: none;
			clear: none;
		}
	}

	.carousel-wrapper {

		position: relative;
		max-width: none;
		width: 100%;
		margin-top: 0px;
	}

	.carousel {

		// ref community-landing

		.overlay-title {

			position: absolute;
			top: 67px;
			left: -75px;
			width: 100%;
			text-align: left;
			height: auto;
			z-index: 10;
			margin: 0;
			padding: 0;
			pointer-events: none;
		}

		.cta {

			.arrow {
				
				position: relative;
				top: 5px;
				overflow: hidden;
				padding-left: 0px;
				margin-left: 5px;
				line-height: 16px;
				vertical-align: top;

				svg {
					vertical-align: middle;
				}
			}
		}
	}

	.cta {
		text-align: center;
		margin-top: 40px;
	}

	.slider-content {

		position: relative;
		text-align: center;
		overflow: visible;
		margin-top: 0px;

		.slider-arrows {

			position: absolute;
			width: 100%;

			.slider-arrow {

				position: absolute;
				visibility: visible;
				width: 50px;
				height: 37px;

				button {
					position: relative;
					height: inherit;
					overflow: hidden !important;
					padding: 10px;
				}

				svg {
					width: 30px;
				}
			}

			.slider-arrow-next {
				right: -60px;
			}

			.slider-arrow-prev {
				left: -60px;
			}
		}

		.slider-pagination {

			display: none;
			position: absolute;
			left: 0px;
			right: 0px;
			text-align: center;
			overflow: hidden;

			.slider-pagination-item {

				position: relative;
				display: inline-block;
				width: 7px;
				height: 7px;
				background: transparent;
				opacity: 0.5;
				padding: 15px;

				cursor: pointer;

				-webkit-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				   -moz-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				     -o-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				        transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

				-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				     -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
				        transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

				&:not(:last-child){
					margin-right: 5px;
				}

				.slider-pager-icon {

					position: absolute;
					top: 15px - 3px;
					left: 15px - 3px;
					width: 6px;
					height: 6px;
					border: 4px solid #1e1e1e;

					-webkit-border-radius: 7px;
					-moz-border-radius: 7px;
					border-radius: 7px;

					-webkit-transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					   -moz-transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					     -o-transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					        transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

					-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					     -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					        transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */
				}

				.slash {

					position: absolute;
					visibility: hidden;
					top: 14px;
					left: -12px;
					width: 56px;
					height: 2px;
					background: #1e1e1e;
				}

				&:hover {
					opacity: 1 !important;
				}

				&.selected {
					
					opacity: 1 !important;

					.slider-pager-icon {
					
						top: 15px - 10px;
						left: 15px - 10px;
						width: 20px;
						height: 20px;
						border: 2px solid #1e1e1e;		

						-webkit-border-radius: 20px;
						-moz-border-radius: 20px;
						border-radius: 20px;
					}			
				}
			}
		}
	}

	.slider-wrapper {

		position: relative;
		display: inline-block;
		max-width: initial;
		width: auto;
	}

	.slider {
		position: relative;
		overflow: visible;
		overflow-y: initial;
		z-index: 1;
	}

	.slider-main-item,
	.slider-side-item {

		position: absolute;
		width: inherit;

		.images-wrapper {

			position: relative;
			width: inherit;
			height: inherit;
			overflow: hidden;
		}

		.slider-item-link {
			position: absolute;
			top: 0px;
			left: 0px;
			overflow: hidden;
			width: 100%;
			height: auto;
		}

		.tabs-wrapper {
			position: absolute;
			width: 100%;
			bottom: -15px;
			text-align: center;
		}

		.rollover-tab {

			background-color: #252627;
			display: inline-block;
			float: none;
			height: 30px;
			margin-left: 8px;
			color: #ffffff;
			font-size: 10px;
			letter-spacing: 0.1em;
			line-height: 30px;
			padding: 0 20px;
			text-transform: uppercase;
			text-align: center;
			opacity: 0;
		}
	}

	.slider-main-item-wrapper {
		display: inline-block;
		position: relative;
		max-width: none;
		width: auto;
		z-index: 10;
		background: #fdfdfd;
	}

	.slider-main-item {

		position: relative;
		width: inherit;
		height: inherit;

		// title above slider

		.title {

			position: relative;
			height: 80px;
			margin: 0 auto 23px;
			padding: 0 0 0 8.3%;
			overflow-x: visible;
			overflow-y: hidden;
		
			@media(min-width: 992px) and (max-width:1199px){
				margin-bottom: 3px;
			}

			@media(min-width: 768px) and (max-width:991px){
				margin-bottom: 3px;
			}

			.title-inner {

				position: relative;
				width: inherit;
				height: inherit;
			}

			h3 {

				position: absolute;
				right: 100%;
				top: 0px;
				float: none;
				text-align: right;
				margin-right: -93px;
				line-height: 1;
		
				@media(min-width: 992px) and (max-width:1199px){
					font-size: 60px;
					top: 3px;
				}

				@media(min-width: 768px) and (max-width:991px){
					font-size: 60px;
					top: 3px;
				}
			}

			.description {

				position: absolute;
				left: 100px;
				top: 20px;
			}

			.desc-title {

				position: relative;
				display: inline-block;
				float: none;
				top: 0px;
				left: 0px;
				font-size: 14px;
				line-height: 18px;
				text-transform: uppercase;
			}

			.desc-subtitle {

				position: relative;
				display: inline-block;
				float: none;
				top: 0px;
				left: 0px;
				font-size: 12px;
				line-height: 20px;
				color: #a9adb0;
				font-family: "Maison Neue Book", Calibri, Arial, Roboto, sans-serif;
			}
		}
	}

	.slider-side-item {

		position: absolute;
		top: 0px;

		&.side-item-left {
			left: 0px;
		}

		&.side-item-right {
			right: 0px;
		}

		.slider-item-link {
			background: #fdfdfd;
		}

		.slider-item-image {
			top: 0px;
			left: 0px;
			opacity: 0.3;
		}
	}

	.slider-wrapper {
		position: relative;
		display: inline-block;
	}

	.overlay {
		// position: absolute;
		// top: 0px;
		// left: 0px;
		// width: 100%;
		// height: 100%;
		// background: #fdfdfd;
		// z-index: 100;
		// visibility: hidden;
	}
}