
.btn-category-thumb {

	overflow: visible;
	margin-bottom: -5px;

	&.grid-thumb {
		margin-bottom: 32px;
	}

	&.bottom-center-label {
		margin-bottom: 55px;
	}

	a {
		display: inline-block;
		position: relative;
	}

	.arrow {
		position: relative;
		display: inline-block;
		top: -1px;
		width: 29px;
		height: 5px;
		margin-left: 7px;
		overflow: hidden;
		visibility: hidden;
	}

	.category-name {

		text-transform: uppercase;
		font-size: 11px;
		color: #1e1e1e;
		display: block;
		float: left;
		clear: both;
		letter-spacing: 0.02em;
		margin-bottom: 0px;
		padding-bottom: 16px;
		font-family: "Maison Neue Demi",Calibri,Arial,Roboto,sans-serif;
		font-weight: 600;

		svg {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}

	.category-thumb, 
	.img-wrapper {

		display: inline-block;
		position: relative;
		overflow: hidden;
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.tabs-wrapper {
		position: absolute;
		width: 100%;
		bottom: -6px;
		text-align: center;
	}

	.rollover-tab {

		background-color: #252627;
		display: inline-block;
		float: none;
		height: 30px;
		color: #ffffff;
		font-size: 10px;
		letter-spacing: 0.1em;
		line-height: 30px;
		padding: 0 20px;
		text-transform: uppercase;
		text-align: center;
		opacity: 0;

		&:not(:first-child){
			margin-left: 8px;
		}
	}
}