
.modal {

	position: absolute;
	z-index: 99999;
	overflow: visible;

	@media(min-width: 768px) and (max-width:991px){
		padding-right: 0px;
	}

	-webkit-transition: none !important;
	   -moz-transition: none !important;
	     -o-transition: none !important;
	        transition: none !important;
}

.modal-dialog {

	top: 0px !important;
	width: 880px;	
	height: 0px;
	margin: 0 auto;

	@media(min-width: 768px) and (max-width:991px){
		width: 700px;
	}

	&.modal-sm {
		max-width: 486px;
	}

	.modal-header {
		
		position: absolute;
		width: 100%;
		height: 90px;

		hr {
			margin: 23px auto 32px !important;
		}

		button {
			z-index: 10;
		}
	}

	.modal-body {

		@media(min-width: 768px) and (max-width:991px){
			padding: 60px 30px 60px 64px !important;
		}
	}

	.modal-content {

		position: absolute;
		top: 90px;
		background: #fdfdfd;
		width: 100%;

		.centered {
			text-align: center;
		}
	}

	textarea {

		margin-bottom: 25px;
		padding: 10px !important;

		-webkit-transition: box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		   -moz-transition: box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		     -o-transition: box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		        transition: box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

		-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		     -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		        transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

		&:hover, &:focus {
			margin-bottom: 23px;
		}
	}

	input {

		padding: 10px !important;

		-webkit-transition: box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		   -moz-transition: box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		     -o-transition: box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		        transition: box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -webkit-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000), -moz-box-shadow 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

		-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		     -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		        transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */
	}

	label {
		letter-spacing: 0.06em;
	}

	.horizontal-sm {
		left: 0px;
		height: 2px;
		text-align: left;
		margin: 26px 0px;

		&.centered {
			margin: 26px auto;
		}
	}

	.product-callout {
		
		img {
			margin-left: -15px;
		}

		.horizontal-sm {
			left: 0px;
			text-align: left;
			margin: 26px 0px 23px 0px;
		}

		@media(min-width: 768px) and (max-width:991px){
			width: calc(50% - 25px);
			padding: 0;
			margin-right: 15px;
		}
	}

	form {

		.checkbox {
			
			position: relative;
			float: left;
			width: 100%;
			margin-bottom: 16px;

			label {
				padding: 0px;
				margin: 0px;
			}
		}

		.btn-primary {
			margin-top: 15px;
		}
	}
}

#modal-backdrop {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: #292929;
	opacity: 0; 
	z-index: 99999;
}