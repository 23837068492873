
/* margin modifiers */

.no-margin {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}

.no-margin-top { margin-top: 0px !important; }
.no-margin-right { margin-right: 0px !important; }
.no-margin-bottom { margin-bottom: 0px !important; }
.no-margin-left { margin-left: 0px !important; }

.horiz-centered {
	margin-left: auto !important;
	margin-right: auto !important;
}

/* padding modifiers */

.no-padding {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.no-padding-top { padding-top: 0px !important; }
.no-padding-right { padding-right: 0px !important; }
.no-padding-bottom { padding-bottom: 0px !important; }
.no-padding-left { padding-left: 0px !important; }

/* border modifiers */

.no-border {
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.no-border-top { border-top: 0 none !important; }
.no-border-right { border-right: 0 none !important; }
.no-border-bottom { border-bottom: 0 none !important; }
.no-border-left { border-left: 0 none !important; }

/* spacers (usually used as <hr/>) */

.spacer {

	position: relative;
	width: 100%;
	clear: both;
	background: transparent;
	border: 0 none;
	margin: 0;
	padding: 0;
}

.spacer-0 { padding-top: 0px }
.spacer-1 { padding-top: 1px }
.spacer-2 { padding-top: 2px }
.spacer-3 { padding-top: 3px }
.spacer-4 { padding-top: 4px }
.spacer-5 { padding-top: 5px }
.spacer-6 { padding-top: 6px }
.spacer-7 { padding-top: 7px }
.spacer-8 { padding-top: 8px }
.spacer-9 { padding-top: 9px }
.spacer-10 { padding-top: 10px }
.spacer-20 { padding-top: 20px }
.spacer-30 { padding-top: 30px }
.spacer-40 { padding-top: 40px }
.spacer-50 { padding-top: 50px }
.spacer-60 { padding-top: 60px }
.spacer-70 { padding-top: 70px }
.spacer-80 { padding-top: 80px }
.spacer-90 { padding-top: 90px }
.spacer-100 { padding-top: 100px }
.spacer-110 { padding-top: 110px }
.spacer-120 { padding-top: 120px }
.spacer-140 { padding-top: 140px }
.spacer-160 { padding-top: 160px }
.spacer-180 { padding-top: 180px }
.spacer-200 { padding-top: 200px }

@media(min-width: 768px){

	.spacer-xs-0 { padding-top: 0px }
	.spacer-xs-1 { padding-top: 1px }
	.spacer-xs-2 { padding-top: 2px }
	.spacer-xs-3 { padding-top: 3px }
	.spacer-xs-4 { padding-top: 4px }
	.spacer-xs-5 { padding-top: 5px }
	.spacer-xs-6 { padding-top: 6px }
	.spacer-xs-7 { padding-top: 7px }
	.spacer-xs-8 { padding-top: 8px }
	.spacer-xs-9 { padding-top: 9px }
	.spacer-xs-10 { padding-top: 10px }
	.spacer-xs-20 { padding-top: 20px }
	.spacer-xs-30 { padding-top: 30px }
	.spacer-xs-40 { padding-top: 40px }
	.spacer-xs-50 { padding-top: 50px }
	.spacer-xs-60 { padding-top: 60px }
	.spacer-xs-70 { padding-top: 70px }
	.spacer-xs-80 { padding-top: 80px }
	.spacer-xs-90 { padding-top: 90px }
	.spacer-xs-100 { padding-top: 100px }
	.spacer-xs-110 { padding-top: 110px }
	.spacer-xs-120 { padding-top: 120px }
	.spacer-xs-140 { padding-top: 140px }
	.spacer-xs-160 { padding-top: 160px }
	.spacer-xs-180 { padding-top: 180px }
	.spacer-xs-200 { padding-top: 200px }
}

@media(min-width: 992px){

	.spacer-sm-0 { padding-top: 0px }
	.spacer-sm-1 { padding-top: 1px }
	.spacer-sm-2 { padding-top: 2px }
	.spacer-sm-3 { padding-top: 3px }
	.spacer-sm-4 { padding-top: 4px }
	.spacer-sm-5 { padding-top: 5px }
	.spacer-sm-6 { padding-top: 6px }
	.spacer-sm-7 { padding-top: 7px }
	.spacer-sm-8 { padding-top: 8px }
	.spacer-sm-9 { padding-top: 9px }
	.spacer-sm-10 { padding-top: 10px }
	.spacer-sm-20 { padding-top: 20px }
	.spacer-sm-30 { padding-top: 30px }
	.spacer-sm-40 { padding-top: 40px }
	.spacer-sm-50 { padding-top: 50px }
	.spacer-sm-60 { padding-top: 60px }
	.spacer-sm-70 { padding-top: 70px }
	.spacer-sm-80 { padding-top: 80px }
	.spacer-sm-90 { padding-top: 90px }
	.spacer-sm-100 { padding-top: 100px }
	.spacer-sm-110 { padding-top: 110px }
	.spacer-sm-120 { padding-top: 120px }
	.spacer-sm-140 { padding-top: 140px }
	.spacer-sm-160 { padding-top: 160px }
	.spacer-sm-180 { padding-top: 180px }
	.spacer-sm-200 { padding-top: 200px }
}

@media(min-width: 1200px){

	.spacer-md-0 { padding-top: 0px }
	.spacer-md-1 { padding-top: 1px }
	.spacer-md-2 { padding-top: 2px }
	.spacer-md-3 { padding-top: 3px }
	.spacer-md-4 { padding-top: 4px }
	.spacer-md-5 { padding-top: 5px }
	.spacer-md-6 { padding-top: 6px }
	.spacer-md-7 { padding-top: 7px }
	.spacer-md-8 { padding-top: 8px }
	.spacer-md-9 { padding-top: 9px }
	.spacer-md-10 { padding-top: 10px }
	.spacer-md-20 { padding-top: 20px }
	.spacer-md-30 { padding-top: 30px }
	.spacer-md-40 { padding-top: 40px }
	.spacer-md-50 { padding-top: 50px }
	.spacer-md-60 { padding-top: 60px }
	.spacer-md-70 { padding-top: 70px }
	.spacer-md-80 { padding-top: 80px }
	.spacer-md-90 { padding-top: 90px }
	.spacer-md-100 { padding-top: 100px }
	.spacer-md-110 { padding-top: 110px }
	.spacer-md-120 { padding-top: 120px }
	.spacer-md-140 { padding-top: 140px }
	.spacer-md-160 { padding-top: 160px }
	.spacer-md-180 { padding-top: 180px }
	.spacer-md-200 { padding-top: 200px }
}

@media(min-width: 1480px){

	.spacer-lg-0 { padding-top: 0px }
	.spacer-lg-1 { padding-top: 1px }
	.spacer-lg-2 { padding-top: 2px }
	.spacer-lg-3 { padding-top: 3px }
	.spacer-lg-4 { padding-top: 4px }
	.spacer-lg-5 { padding-top: 5px }
	.spacer-lg-6 { padding-top: 6px }
	.spacer-lg-7 { padding-top: 7px }
	.spacer-lg-8 { padding-top: 8px }
	.spacer-lg-9 { padding-top: 9px }
	.spacer-lg-10 { padding-top: 10px }
	.spacer-lg-20 { padding-top: 20px }
	.spacer-lg-30 { padding-top: 30px }
	.spacer-lg-40 { padding-top: 40px }
	.spacer-lg-50 { padding-top: 50px }
	.spacer-lg-60 { padding-top: 60px }
	.spacer-lg-70 { padding-top: 70px }
	.spacer-lg-80 { padding-top: 80px }
	.spacer-lg-90 { padding-top: 90px }
	.spacer-lg-100 { padding-top: 100px }
	.spacer-lg-110 { padding-top: 110px }
	.spacer-lg-120 { padding-top: 120px }
	.spacer-lg-140 { padding-top: 140px }
	.spacer-lg-160 { padding-top: 160px }
	.spacer-lg-180 { padding-top: 180px }
	.spacer-lg-200 { padding-top: 200px }
}
