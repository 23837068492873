
#rate-product-overlay {

	.modal-dialog {

		textarea#review-headline {

			margin-bottom: 17px;

			-webkit-box-sizing: border-box;
			  -moz-box-sizing: border-box;
			  box-sizing: border-box;

			&:hover, &:focus {
				margin-bottom: 15px;
			}
		}

		textarea#review {

			margin-bottom: 17px;

			-webkit-box-sizing: border-box;
			  -moz-box-sizing: border-box;
			  box-sizing: border-box;

			&:hover, &:focus {
				margin-bottom: 17px;
			}
		}

		.btn-primary {
			margin-top: 3px;
		}

		.product-callout {
			
			padding-left: 30px;

			small {
				margin-top: 23px;
			}

			span {
				color: #37383a;
			}
		}

		.review-col {
			padding-left: 30px;
		}

		i.global-views-star-rating-empty {
			cursor: pointer;
			margin-right: 0px;
			padding: 0 7px;
			letter-spacing: 0;
		}
	}
}