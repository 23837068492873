
.header-secondary-wrapper.pdp {

	.header-logo-wrapper {

		a {

			white-space: nowrap;

			-webkit-transition: opacity 140ms ease-out;
				-moz-transition: opacity 140ms ease-out;
				-o-transition: opacity 140ms ease-out;
				transition: opacity 140ms ease-out;
		}
	}

	.attribute-selector-container {
		
		.attribute-selector {

			padding: 10px 20px 6px;

			&:hover,
			&.expanded {

				padding: 9px 19px 5px;

				.icon-utility-menu {
					top: 7px;
					right: 9px;
				}
			}
		}

		.icon-utility-menu {
			
			position: absolute;
			float: none;
			top: 8px;
			right: 10px;
		}
	}
}