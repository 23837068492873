
.jumbo-video {

	position: relative;
	overflow: hidden;
	margin-bottom: 73px;

	&.contained {
		margin-bottom: 0px;
	}

	@media(min-width: 992px) and (max-width:1199px){
		margin-bottom: 38px;
	}

	@media(min-width: 768px) and (max-width:991px){
		margin-bottom: 38px;
	}

	.jumbo-video-play-button {

		position: absolute;
		left: 50%;
		top: 50%;
		margin: -40px 0 0 -40px;
		z-index: 10;

		svg {
			width: 80px;
			height: 80px;
		}

		.circle {
			left: -2px;
			top: -1px;
		}

		.icon {
			font-size: 35px;
		}
	}

	img {

		position: absolute;
		display: block;
		top: 0px;
		left: 0px;
		width: 100%;
		height: auto;
		z-index: 5;
	}

	.video-container {

		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.jumbo-video::after {
	display: none;
}