
.social-bottom-images {
	
	background: transparent;
	white-space: nowrap;
	overflow: hidden;

	.social-bottom-image-wrapper {
		
		display: inline-block;
		position: relative;
		overflow: hidden;
		margin-right: -4px;
		margin-bottom: -10px;

		.social-bottom-image {

			position: relative;
			float: none;
			width: 100%;
			height: auto;
		}
	}
}