
.jumbo-spotlight {

	position: relative;
	overflow: hidden;

	&.contained {
		
		small {
			@include maison-bold();
			font-size: 18px;
		}

		@media(min-width: 1200px) and (max-width:1479px){

			h2 {
				font-size: 60px;
				line-height: 65px;
			}

		}

		@media(min-width: 992px) and (max-width:1199px){
			
			h2 {
				font-size: 48px;
				line-height: 1.167;
			}
		}

		@media(min-width: 768px) and (max-width:1199px){
			
			.image {
				height: 300px;
			}

			.copy {
				
				.call-out {
					padding: 40px 60px 0px;
					height: 300px;
					white-space: nowrap;
				}
			}

			.centered {
				margin-top: 55px;
				margin-bottom: 55px;
			}

			h2 {
				font-size: 40px;
				line-height: 1.167;
				color: #ffffff;
				text-align: left;
			}

			small {
				color: #ffffff;
				text-align: left;				
			}
		}
	}

	&.white {

		.btn-outline {
			color: #fdfdfd;

			.text-over {
				color: inherit;

				&:hover {
					color: #1e1e1e;
				}
			}
		}
	}

	small {
		margin-top: 19px;
		margin-bottom: 15px;
	}

	p {
		letter-spacing: 0.01em;		
	}

	.centered {
		// margin-top: 185px;
		// margin-bottom: 185px;
	}

	.image {
		
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 0;

		background-position: center top;
	}

	.copy {
		position: relative;
		top: 0px;
		left: 0px;
		width: 100%;
		z-index: 2;
	}

	.text-container {
		max-width: 400px;
	}

	.call-out {

		padding-top: 0px !important;

		&:before {
			// display: block;
			// position: absolute;
			// height: inherit;
		}

		ul {

			margin-top: 23px;
			letter-spacing: 0.01em;

			>li {
				margin-bottom: -2px;
			}

			li {
				list-style: none;
				line-height: 24px;
			}

			li:before {
			    content:"•";
			    font-size:18px;
			    vertical-align:middle;
			    line-height:24px;
			    margin-right: 6px;
			}
		}
	}

	@media(min-width: 1200px) and (max-width:1479px){
		
		h2 {
			font-size: 48px;
			line-height: 1.167;
		}
	}

	@media(min-width: 992px) and (max-width:1199px){

		.col-md-9.col-md-offset-16 {
			margin-left: 62.5%;
			width: 29.16667%;
		}

		.col-md-9.col-md-offset-2 {
			width: 29.16667%;			
		}
	}

	@media(min-width: 768px) and (max-width:1199px){
		
		h2 {
			font-size: 36px;
			line-height: 1.167;
			text-align: left;
		}

		p, ul {
			font-size: 13px;
			line-height: 24px;
		}
	}

	@media(min-width: 768px) and (max-width:991px){

		.centered {
			margin-top: 85px;
			margin-bottom: 85px;
			margin-left: -13px;
		}

		.col-md-9.col-md-offset-16 {

			max-width: 400px;
			width: 100%;
			float: right;
			
			h2 { 
				color: #fff;
			}

			p, ul { 
				color: #cccfd1;
			}
		}
	}
}