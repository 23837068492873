
@import 'utils/reset';
@import 'utils/global';
@import 'utils/variables';

/* misc elements */

@import 'bits/fancy_title';
@import 'bits/jumbo_video_trigger_button';
@import 'bits/social_share_icons';
@import 'bits/style_guide';
@import 'bits/spacers';
@import 'bits/tile_picker';
@import 'bits/view_changer';

/* large page blocks */

@import 'blocks/header';
@import 'blocks/footer';

/* modals */

@import 'modals/added_to_cart';
@import 'modals/generic';
@import 'modals/image_overlay';
@import 'modals/jumbo_video';
@import 'modals/product_zoom';
@import 'modals/rate_this_product';
@import 'modals/register_login';
@import 'modals/shop_the_look';
@import 'modals/region_language';

/* individual page modules */

@import 'modules/gender_block';
@import 'modules/gender_bottom_images';
@import 'modules/gender_community';
@import 'modules/gender_featured_categories';
@import 'modules/gender_masonry';

@import 'modules/attribute_selector';
@import 'modules/buttons';
@import 'modules/category_thumb';
@import 'modules/collection_thumb';
@import 'modules/color_select_item';
@import 'modules/community_sidebar';
@import 'modules/community_sticky_nav';
@import 'modules/facets_bar';
@import 'modules/facets_categories';
@import 'modules/fancy_title';
@import 'modules/featured_call_out';
@import 'modules/jumbo_feature';
@import 'modules/jumbo_slider';
@import 'modules/jumbo_slider_detailed';
@import 'modules/jumbo_spotlight';
@import 'modules/jumbo_video';
@import 'modules/most_popular';
@import 'modules/navigation';
@import 'modules/new_looks';
@import 'modules/other_collections';
@import 'modules/pdp_sticky_nav';
@import 'modules/product_slider';
@import 'modules/shadow_button';
@import 'modules/play_button';
@import 'modules/product_pairing';
@import 'modules/product_reviews_content';
@import 'modules/product_slider';
@import 'modules/quick_shop';
@import 'modules/recently_viewed';
@import 'modules/related_items';
@import 'modules/sales_featured_callout';
@import 'modules/shop_more';
@import 'modules/shop_subcategory';
@import 'modules/shop_the_look';
@import 'modules/shop_the_look_slider';
@import 'modules/standard_slider';
@import 'modules/stories_block';
@import 'modules/team_detail_side_widgets';
@import 'modules/team_picks';
@import 'modules/video_block';

/* page specific */

@import 'pages/community';
@import 'pages/team_detail';

/* general */

html {
	overflow-y: scroll;
}

html, body {
	position: relative;
	padding: 0;
	margin: 0;	
}

body {

	overflow: hidden;
	background: #f0f0f4;
	opacity: 0;

	-webkit-transition: opacity 400ms cubic-bezier(0.190, 0, 0.450, 0.975); /* older webkit */
	-webkit-transition: opacity 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	   -moz-transition: opacity 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	     -o-transition: opacity 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	        transition: opacity 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); /* custom */

	-webkit-transition-timing-function: cubic-bezier(0.190, 0, 0.450, 0.975); /* older webkit */
	-webkit-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	   -moz-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	     -o-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	        transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); /* custom */
}

::-webkit-scrollbar {
    all:unset;
}

a {

	-webkit-transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);
	   -moz-transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);
		 -o-transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);
			transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);

	-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
	   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		 -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
			transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

	&:active, &:focus {
		color: initial;
	}
}

.social-share-icons a i {

	-webkit-transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);
	   -moz-transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);
		 -o-transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);
			transition: color 160ms cubic-bezier(0.280, 0.010, 0.175, 1.000);

	-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
	   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
		 -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
			transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */
}

textarea {
	border: 1px solid #cccfd1;
}

.icon {
	font-family: 'billabong_dingsregular';
}

.zoom-cursor {
	cursor: url(../img/zoom-cursor.png) 18 18, auto;
}

//

#layout {

	position: relative;
	min-width: 100%;
	overflow: hidden;
}

#main-container {

	position: relative;
	max-width: 1920px;
	margin: 0 auto;
	overflow: hidden;
	background: #fdfdfd;

	-webkit-transition: padding-top 400ms cubic-bezier(0.190, 0, 0.450, 0.975); /* older webkit */
	-webkit-transition: padding-top 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	   -moz-transition: padding-top 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	     -o-transition: padding-top 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	        transition: padding-top 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); /* custom */

	-webkit-transition-timing-function: cubic-bezier(0.190, 0, 0.450, 0.975); /* older webkit */
	-webkit-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	   -moz-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	     -o-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	        transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); /* custom */
}

#main.home {
	
	#main-container {
		background: transparent;
	}
}

.full-bleed {
	padding-left: 0px;
	padding-right: 0px;
	margin-left: 0px;
	margin-right: 0px;
	width: 100%;
}

.no-tablet {

	@media(min-width: 768px) and (max-width:991px){
		display: none;
	}
}

.main,
.layout-container {
	height: auto;
	min-height: 100%;
}

.content-container {

	@media(min-width: 1200px) and (max-width:1479px){
		
		width: 100%;
		padding-left: 40px;
		padding-right: 40px;
	}

	@media(min-width: 992px) and (max-width:1199px){
		
		width: 100%;
		padding-left: 40px;
		padding-right: 40px;
	}

	@media(min-width: 768px) and (max-width:991px){

		width: 100%;
		padding-left: 40px;
		padding-right: 40px;
	}
}

