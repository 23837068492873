
.community {

	.page-content .details {
		li, p {
			color: #2a2a2a;
		}
	}
}

.community-detail > * {
	
	&.layout-container {
		margin-bottom: 0px;

		#content {

			#live-video {

				@media(max-width: 1199px) {
					text-align: center;

					.tag.blue {
						width: auto;
						float: none;

						small {
							display: none;
						}
					}

					.tag-remaining {
						width: 100%;
						margin-top: 15px;
					}

					.st-group {
						border-top: none;
					}
				}
			}
		}
	}

	@media(min-width: 768px) and (max-width:1199px){

		blockquote p {

			font-family: "Maison Neue Bold", Calibri, Arial, Roboto, sans-serif;
			font-style: normal;
			color: #2a2a2a;
			font-size: 36px;
			line-height: 48px;
			letter-spacing: 0.025em;
		}
	}
}

.community-landing {

	.community-landing-content {
		min-height: 720px;
	}
}