.social-share-icons {

	&.top {

	}

	&.bottom {
		margin-bottom: 60px;
	}

	.social-icons-bottom {

		width: 100%;
		margin-top: -5px;
		
		.row {
			white-space: nowrap;
			text-align: center;
		}

		a {
			
			display: inline-block;
			position: relative;
			width: auto;
			float: none;

			font-size: 24px;
			padding: 0 12px;

			-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1);
			-webkit-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			   -moz-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			     -o-transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			        transition: color 160ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
		}

	}	
}

.social-icons,
.social-icons-bottom {
	
	a {
		display: inline-block;
		position: relative;
		width: auto;
		float: none;
		font-size: 23px;
		padding: 0 12px;
		color: #7e8183;
		line-height: 36px;
		width: 56px;
		text-align: center;
	}

	.row {
		white-space: nowrap;
	}

	.bd-email {

		font-family: 'billabong_dingsregular';
		font-style: normal;
		background: none !important;

		&:before {
			text-transform: initial !important;
			content: '0' !important;
		}
	}
}