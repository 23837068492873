
.shop-subcategories {
	
	.shop-subcategory {
		
		margin-bottom: 73px;
		
		.fancy-title {
			margin-bottom: 117px !important;
		}

		@media(max-width: 1199px){
			
			margin-bottom: 43px;

			.fancy-title {
				margin-bottom: 57px !important;
			}
		}
	}

	.btn-secondary {
		background: #fdfdfd;
	}
}

.subcategory-subcategories {
	
	padding-top: 0px !important;
	margin-bottom: 72px;

	&.product-grid-cta {
		margin-bottom: 0px;
		padding-bottom: 2px;
	}

	.subcategory {
		
		margin-top: 0px;
		margin-bottom: 108px;

		&.product-grid {
			margin-bottom: 18px;
		}
	}

	@media(max-width: 1199px){
		
		margin-bottom: 32px;
		
		.subcategory {
			margin-bottom: 58px;
		}
	}
}

.subcategory-landing {

	.feature {
		margin-top: 0px;
	}
}

.category {
	
	.first-feature {
		padding-top: 0px;
		border-top: none;
	}
}