
.fancy-title {
	
	border-color: #d1d4d6;

	.fancy-title-middle-text, 
	.fancy-title-right-link-wrapper, 
	.fancy-title-left-text {
		background: #fdfdfd !important;
	}

	&.sm {

		.fancy-title-right-link-arrow {
			margin-top: -3px;
		}
	}

	.fancy-title-right-link-arrow {
		
		overflow: hidden;

		svg {
			position: relative;
		}
	}

	.fancy-title-right-link {
		color: #cccfd1;
	}

	.fancy-title-middle-text {
		
		@media(min-width: 768px) and (max-width:991px){
			padding: 0 25px; 
		}
	}

	.fancy-title-middle-text-back {
		
		text-transform: none;

		@media(min-width: 768px) and (max-width:991px){
			font-size: 80px;
		}
	}

	.fancy-title-middle-text-front {
		
		@media(min-width: 768px) and (max-width:991px){
			font-size: 18px;
		}
	}
		
	@media(min-width: 768px) and (max-width:1199px){
		margin-bottom: 65px !important;
	}
}