
.product-zoom-modal {

	overflow: hidden !important;
	overflow-x: hidden !important;
	overflow-y: hidden !important;
	background: #f0f0f5;

	&.in button.close {
		top: 20px;
		right: 20px;
		z-index: 100;
	}

	.modal-dialog {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}

	.modal-content {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		max-width: 1920px;
		background: #f0f1f3;
		border: 0 none;
	}

	.modal-backdrop {
		background-color: #f0f0f5 !important;
	}

	.thumbs-container {

		position: absolute;
		top: 50%;
		margin-left: 90px;
		margin-top: -190px;
		z-index: 10;

		.thumbs-slider-wrapper {

			position: relative;
			max-height: 300px;
			padding-bottom: 18px;
			margin-bottom: -18px;
			overflow: hidden;
			
			@media(min-width: 768px) and (max-width:991px){
				max-height: 240px;
			}
		}

		.thumbs-list {

			width: 100%;

			li {

			    position: relative;
			    overflow: visible;
			    height: 80px !important;
			    display: block !important;
			    margin-top: 20px;

			    &:first-child {
			    	margin-top: 0px;
			    }

			    a {
			    	position: absolute;
			    	left: 0px;
			    	right: 0px;
			    	margin: 0 auto;
			    	border: 0 none !important;
			    	width: 80px !important;
			    	height: 80px !important;
			    }
			}
		}

		.item-details-slider-controls-up, 
		.item-details-slider-controls-down {
		    width: 88px;
		    display: block;
		    margin-left: auto;
		    margin-right: auto;
		    text-align: center;
		}
	}

	.bg-image-wrapper {
		
		position: absolute;
		z-index: 1;

		img {
			position: absolute;
			top: 0px;
			left: 0px;
			max-width: none;
			max-height: none;
		}
	}
}