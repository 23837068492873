
.video-block {

	overflow: hidden;
	background: #000;
	
	h2, p, ul { 
		color: #fff;
	}

	&.white {

		h2 { 
			color: #fff;
		}

		p, ul { 
			color: #cccfd1;
		}
	}

	.main-content-wrapper {

		padding: 0px 60px;
		margin: auto;

		@media(min-width: 1480px){
			width: 1420px;
		}

		@media(min-width: 1200px) and (max-width:1479px){
			width: 1170px;
		}

		@media(min-width: 992px) and (max-width:1199px){
			width: 970px;
		}

		@media(min-width: 768px) and (max-width:991px){
			width: 702px;
		}
	}

	.bg-image {

		position: absolute;
		display: block;
		top: 0px;
		left: 0px;
		width: 100%;
		height: auto;
		z-index: 5;
	}

	.video-container {

		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.main-content-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 10;
	}

	.main-content {
		
		position: absolute;
		top: 50%;
		max-width: 80%;
		margin-top: -200px;
		color: #ffffff;
		text-align: left;
		font-family: "Maison Neue Book",Calibri,Arial,Roboto,sans-serif;

		h2 {

			position: relative;
			letter-spacing: 0.006em;
			font-size: 60px;
			line-height: 77px;
			margin-left: -4px;

			.line-wrapper {
				width: 100% !important;
				overflow: hidden;
			}

			.line {
				width: 100% !important;
				white-space: nowrap;
			}
		}

		p {
			@include maison-bold();
			letter-spacing: 0.3em;
			margin-top: 31px;
			margin-bottom: 31px;
			line-height: 1;
			overflow: hidden;
		    
		    color: inherit;
		    text-transform: uppercase;
		    font-size: 15px;

			span {
				display: inline-block;
				position: relative;
			}
		}

		.btn-outline {
			margin-top: 17px;
		}

		.play-icon {

			display: inline-block;
		    font-family: FontAwesome;
		    font-style: normal;
		    color: #fff;

		    text-rendering: auto;
		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;

			&:before {
				content: "\f04b";
			}
		}

		@media(min-width: 1480px){

		}

		@media(min-width: 1200px) and (max-width:1479px){

		}

		@media(min-width: 992px) and (max-width:1199px){

		}

		@media(min-width: 768px) and (max-width:991px){

			margin-top: -120px;
			left: 32px;

			h2 {
				font-size: 36px;
				line-height: 45px;
			}

			p {
				margin-top: 18px;
				margin-bottom: 21px;
				font-size: 12px;
			}

			.btn-video-play {
				margin-top: 0px;
				margin-bottom: -4px;
				margin-left: -5px;
			}
		}
	}
	
	.gender-community-secondary-team-link-arrow {
		vertical-align: middle !important;
	}

	.gender-community-secondary-team-link {
		margin-top: 37px;
	}

	.gender-community-secondary-team-link-arrow {
		overflow: hidden;
	}
}