
.other-collections {

	.fancy-title {
		margin-bottom: 117px;
	}

	.btn-category-thumb {
		padding: 0;
		margin: 0 0 70px;
	}

	.img-wrapper {

		img {
			width: 100%;
			margin: 0 !important;
			padding: 0 !important;
		}
	}
}