
#site-header {

	border-bottom: none;

	-webkit-transition: margin-top 400ms cubic-bezier(0.190, 0, 0.450, 0.975); /* older webkit */
	-webkit-transition: margin-top 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	   -moz-transition: margin-top 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	     -o-transition: margin-top 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	        transition: margin-top 400ms cubic-bezier(0.190, -0.005, 0.450, 0.975); /* custom */

	-webkit-transition-timing-function: cubic-bezier(0.190, 0, 0.450, 0.975); /* older webkit */
	-webkit-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	   -moz-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	     -o-transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); 
	        transition-timing-function: cubic-bezier(0.190, -0.005, 0.450, 0.975); /* custom */

	.site-search {
		background: #fdfdfd;
	}

	.header-message,
	.headache-bar {
	
		width: 100% !important;
		padding: 0 30px;
		max-width: 1920px;
		margin: 0 auto;

		.container {
			width: 100% !important;

			p {
				font-weight: bold;
				letter-spacing: 0.1em;
			}
		}
	}

	.header-menu-settings-anchor,
	.header-store-locator-link,
	.header-profile-login-link,
	.header-profile-register-link,
	.header-menu-wishlist-link {

		&:hover {
			color: #fff;
		}

		span {

			position: relative;
			display: inline-block;
			padding-bottom: 2px;
			border-bottom: 1px solid rgba(255,255,255,0);
					
			-webkit-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1);
			-webkit-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			   -moz-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			     -o-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			        transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */

			&:hover {
				border-bottom: 1px solid rgba(255,255,255,0.6);
			}
		}
	}

	.header-menu-level1 {
		
		white-space: nowrap;

		>li {

			float: none;
			display: inline-block;

			@media(min-width: 992px) and (max-width:1199px){
				margin-left: 17px;
			}

			a {
				
				letter-spacing: 0.1em;
				border-bottom: 2px solid rgba(255,255,255,0);

				-webkit-transition: border-color 300ms cubic-bezier(0.340, 0.065, 0.130, 1);
				-webkit-transition: border-color 300ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				   -moz-transition: border-color 300ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				     -o-transition: border-color 300ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
				        transition: border-color 300ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

				-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
				-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
				        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */

				&:hover {
					border-bottom: 2px solid rgba(255,255,255,0.85);
				}
			}

			&.wildcard {

				@media(min-width: 768px) and (max-width:1199px){
					display: none;
				}
			}
		}
	}
	
	.header-menu-search {

		cursor: pointer;
		margin-left: 22px;
		padding: 4px 15px 6px;
		overflow: hidden;

		.header-menu-search-link {

			&:hover {
				color: #fff;
			}
			
			.header-menu-search-icon {
				
				padding: 0 1px;
				
				&:hover {
					color: #fff;
				}
			}
		}
	}

	.header-site-search {

		.fancy-title {
			border-bottom: 1px solid rgba(42,42,42,0.12);
		}
	}

	.header-menu-cart {

		.header-mini-cart-menu-cart-link  {
			overflow: hidden;
			padding-left: 1px;
		}
	}

	.header-gender-selector {

		border-right: none;

		a {

			-webkit-transition: opacity 400ms cubic-bezier(0.340, 0.065, 0.130, 1);
			-webkit-transition: opacity 400ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			   -moz-transition: opacity 400ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			     -o-transition: opacity 400ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			        transition: opacity 400ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
		}

		@media(min-width: 1480px){
			padding: 0 45px 0 80px;
		}

		@media(min-width: 1200px) and (max-width:1479px){
			padding: 0 30px 0 50px;
		}

		@media(min-width: 992px) and (max-width:1199px){
			padding: 0 40px 0 50px;
		}

		@media(min-width: 768px) and (max-width:991px){
			padding: 0 80px;
		}
	}

	.header-content {
		
		a:not(.header-menu-search):not(.header-menu-cart){
			@include maison-bold();
			letter-spacing: 0.08em;
		}
	}

	.header-main-wrapper {

		z-index: 2;
		max-width: 1920px;
		margin: 0 auto;
	}

	.header-secondary-wrapper {

		max-width: 1920px;
		margin: 0 auto;
		z-index: 1;
		background: #fdfdfd;

		.header-menu-secondary-nav {

			display: inline-block;
			width: 100% !important;

			.header-menu-level1 {

				@media(min-width: 992px) and (max-width:1199px){
					width: 100%;
				}

				a {
					@include maison-bold();
					letter-spacing: 0.12em;
				}
			}
		}
	}

	.header-menu-site-search {

		@media(min-width: 768px) and (max-width: 991px){
			margin-right: 0px;
		}

		@media(min-width: 992px) and (max-width:1199px){
			right: -10px;
			margin-left: 15px;
		}

		.header-menu-site-search-label {

			-webkit-transition: opacity 600ms cubic-bezier(0.340, 0.065, 0.130, 1);
			-webkit-transition: opacity 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			   -moz-transition: opacity 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			     -o-transition: opacity 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
			        transition: opacity 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
			-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
			        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */
		}

		&:hover {
			.header-menu-site-search-label {
				opacity: 1;
			}
		}
	}

	.header-logo {

		@media(min-width: 768px) and (max-width: 991px){

			top: -4px;
			width: 40px;
			height: 27px;

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	.header-menu-secondary-nav,
	.header-menu-level1 {

		@media(min-width: 768px) and (max-width: 991px){
			display: none !important;
		}
	}
}

.headache-bar-closed {
	
	#site-header {
		margin-top: -40px;
	}

	&.sticky-nav {
		
		.header-secondary-wrapper {
			top: 40px;
		}
	}
}

.sales,
.gender,
.collection,
.category,
.team {

	#site-header {
		
		.header-secondary-wrapper {
			@media(min-width: 991px){
				background: transparent;
			}
		}
	}
}

.header-secondary-container, 
.header-main-nav {
	
	width: 100%;
	max-width: 1920px - 80px;
	padding: 0 40px;
	
	@media(min-width: 768px) and (max-width: 991px){
		padding: 0 30px;
	}

	.header-menu-wrapper {
		
		position: absolute;
		left: 365px;
		right: 40px;
		width: auto;
		height: inherit;
		float: none !important;

		@media(min-width: 1200px) and (max-width: 1479px){
			left: 325px;
		}

		@media(min-width: 992px) and (max-width: 1199px){	
			left: 215px;
		}

		@media(min-width: 768px) and (max-width: 991px){
			left: 110px;
			text-align: left;
		}
	}

	.header-logo-wrapper {

		position: relative;
		margin-top: 3px;
		
		figure.right-divider {
			
			position: absolute;
			width: 3px;
			height: 30px;
			top: 2px;
			right: -3px;
			background: url('../img/divider-pattern.png');

			@media(min-width: 768px) and (max-width: 991px){
				display: none;
			}
		}

		.header-go-to-top {
			line-height: 30px;
			white-space: nowrap;
		}
	}

	.header-gender-selector-men-label, 
	.header-gender-selector-women-label {
		margin-top: 4px;
	}

	@media(min-width: 992px) and (max-width:1199px){
		width: 100%;
		padding: 0 30px;
	}

	@media(min-width: 768px) and (max-width:991px){
		width: 100%;
	}
}

.header-compact #site-header {

	border-bottom: none;

	.header-main-nav {
		pointer-events: none;
		display: none;
	}

	.header-secondary-wrapper {

		z-index: 2;
		background: #fdfdfd;
		max-width: 1920px;

		&::after {
			position: absolute;
			left: 0px;
			right: 0px;
			width: auto;
			height: 5px;
			bottom: -5px;
			background: rgba(0,0,0,0.1);
			content: "";
			z-index: 1;
		}

		.header-menu {
			left: 10px;
			right: 20px;
		}

		.header-menu-site-search {
			margin: 0;
		}

		.header-menu-level1 {
			
			>li {

				margin-left: 0px;

				&:not(:first-child){

					@media(min-width: 1480px){
						margin-left: 25px;
					}

					@media(min-width: 1200px) and (max-width:1479px){
						margin-left: 15px;
					}

					@media(min-width: 992px) and (max-width:1199px){
						margin-left: 20px;
					}
				}

				a:not(.btn-solid-shadow) {

					letter-spacing: 0.1em;

					span {
						position: relative;
					    display: inline-block;
					}

					.underline {
						position: absolute;
						top: calc(50% + 10px);
						left: 0px;
						right: 0px;
						height: 1px;
						background: #d1d4d6;
						opacity: 0;
					}
				}
			}
		}

		.header-gender-selector {

			@media(min-width: 1480px){
				padding: 0 45px 0 48px;
			}

			@media(min-width: 1200px) and (max-width:1479px){
				padding: 0 45px 0 50px;
			}

			@media(min-width: 992px) and (max-width:1199px){
				padding: 0 20px 0 30px;
			}

			@media(min-width: 768px) and (max-width:991px){
				padding: 0 80px;
			}
		}

		.header-secondary-container {

			padding: 0;

			@media(min-width: 768px) and (max-width: 991px){				
				padding: 6 40px;
			}

			.header-menu-wrapper {

				@media(min-width:1200px) and (max-width: 1479px){
					left: 380px;
				}

				@media(min-width:992px) and (max-width: 1199px){
					left: 322px;
				}
			}
		}

		.header-go-to-top {

			padding: 0 35px 0 30px;
			margin: 0;

			-webkit-transition: opacity 140ms ease-out;
				-moz-transition: opacity 140ms ease-out;
				-o-transition: opacity 140ms ease-out;
				transition: opacity 140ms ease-out;
		}
	}
}

.header-compact .header-logo {
		
	width: 50px;
	height: 34px;

	svg {
		width: 100%;
		height: 100%;
	}

	@media(min-width: 768px) and (max-width: 991px){
		margin-left: 30px;
	}
}

.header-compact.nav-open,
.search-open {

	.header-secondary-wrapper {

		&::after {
			display: none;
		}
	}
}

.header-compact.fixed-nav {

	#site-header {
		position: absolute;
	}

	.header-secondary-wrapper {

		&::after {
			display: none;
		}
	}
}

.header-bottom-hide {

	.header-menu-cart {

		margin-left: 18px;

		.header-mini-cart-menu-cart-link  {
			overflow: hidden;
			padding-left: 1px;
		}
	}
}

.main {

	#site-header {

		.header-menu {
			position: absolute;
			left: 0px;
			right: 90px;
			float: none;

			@media(min-width: 992px) and (max-width: 1199px){	
				right: 30px;
			}
		}

		.header-secondary-wrapper {
			text-align: center;
		}

		.header-menu-level1 {
			display: inline-block;
			position: relative;
			width: 100%;
		}

		.navigation-link {

			@media(min-width: 1200px) and (max-width: 1479px){
				margin-left: 2%;
			}

			@media(min-width: 992px) and (max-width: 1199px){	
				margin-left: 4%;
			}

			@media(min-width: 768px) and (max-width: 991px){

			}
		}
	}

	.header-secondary-wrapper {

		.header-menu-site-search {
			position: absolute;
			left: auto;
			right: 0px;
			float: none;
			
			.header-menu-site-search-icon {
				padding: 0 1px;
			}
		}
	}
}

// specific pages ------------------------------------------------------------------------------  /

.pdp,
.navigation {

	#site-header {

		@media(min-width: 768px) and (max-width: 991px){
			margin-left: 0px;
		}
	}
}

// special facets menu

.header-compact-facets {

	.header-secondary-wrapper.facets {
		
		width: 100%;
		display: block;
		position: fixed;
		top: 0;
		z-index: 9999;

		.attribute-selector-container {
			
			margin-top: 3px;

			.attribute-selector {
				
				height: 30px;

				&.expanded,
				&:hover.expanded {
					height: auto;
				}
			}
		}

		.control-label {
			padding: 0 10px;
		}
	}
}

// pages with xl jumbo sliders -----------------------------------------------------------------  /

.category,
.collection,
.community,
.gender,
.pdp,
.sales,
.team
 {

	.header-secondary-container, 
	.header-main-nav {

		.header-menu-wrapper {

			@media(min-width: 1200px) and (max-width: 1479px){
				left: 325px;
			}

			@media(min-width: 992px) and (max-width: 1199px){	
				
				left: 285px;

				.header-menu-level1 >li {
					margin-left: 4% !important;
				}
			}

			@media(min-width: 768px) and (max-width: 991px){
				left: 110px;
				text-align: left;
			}
		}
	}

	.header-compact {

		.header-secondary-container, 
		.header-main-nav {

			.header-menu-wrapper {

				@media(min-width: 768px) and (max-width: 991px){
					left: 100px;
					text-align: left;
				}
			}
		}
	}
}

// solid white headers

.layout-container:not(.header-compact) #site-header.solid {

	.header-secondary-wrapper {
		border-bottom: 1px solid rgba(42,42,42,0.12);
	}

	.header-secondary-wrapper.pdp {
		border-bottom: 1px solid rgba(169, 173, 176, 0.3);
		background: #fdfdfd;
	}
}

// header only

.layout-container:not(.header-compact){

	.header-secondary-wrapper {

		.header-menu-site-search {

			@media(min-width: 768px) and (max-width:1199px){
				right: 40px;
			}
		}
	}
}

// sections with special sticky navs -----------------------------------------------------------  /

.community,
.facets,
.navigation,
.pdp {

	#site-header {

		.header-menu-secondary-nav {

			.navigation-link {

				a {

					position: relative;
					display: inline-block;
					padding-bottom: 2px;
					border-bottom: 2px solid rgba(228,229,230,0);
							
					-webkit-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1);
					-webkit-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
					   -moz-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
					     -o-transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);
					        transition: border 600ms cubic-bezier(0.340, 0.065, 0.130, 1.005);

					-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1); /* older webkit */
					-webkit-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
					   -moz-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
					     -o-transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); 
					        transition-timing-function: cubic-bezier(0.340, 0.065, 0.130, 1.005); /* custom */

					&:hover {
						border-bottom: 2px solid rgba(228,229,230,1);
					}
				}
			}
		}
	}
	
	#content {

		.header-secondary-wrapper {
			top: 0;
			display: none;
			position: fixed;
			width: 100%;
			z-index: 9999;
		}

		.shopping-layout-breadcrumb {
			
			background: #fdfdfd;

			.global-views-breadcrumb {

				width: 100%;
				padding: 0 40px;
			}
		}	
	}
	
	.header-compact-community,
	.header-compact-pdp,
	.header-compact-facets {

		#content {

			.header-menu-wrapper {

				left: 260px;

				@media(max-width: 1480px){
					left: 235px;
				}
			}

			.header-secondary-wrapper {
				
				display: block;
				background: #fdfdfd;
				max-width: 1920px;
				z-index: 10000 !important;

				@media(min-width: 768px) and (max-width:991px){
					padding: 10px 0px 9px 0px;
				}

				&.facets {

					.header-logo-content {
						border-right: 3px double #e5e6e7;
						border-left: 3px double #e5e6e7;
						padding: 0 40px;
					}
					
					@media(min-width: 768px) and (max-width:991px){

						.form-group-container {
							width: 78%;
							padding: 0px;
						}

						.header-menu-wrapper {
							left: 145px;
						}

						.header-logo {
							margin-left: 0px;
						}

						.header-logo-content {
							border-left: none;
							border-right: none;
							padding: 0 40px 0 0;
						}
					}
				}
			}

			.header-secondary-container {

				position: relative;
				width: 100%;

				.community-sticky-nav,
				.facets-sticky-nav,
				.pdp-sticky-nav {

					float: none;
					width: 100%;
					text-align: right;
					white-space: nowrap;
					
					h2 {
						
						position: absolute;
						left: 0px;
						white-space: nowrap;
						line-height: 40px;						
						text-transform: uppercase;
						color: #1e1e1e;
						padding: 0 20px;

						font-family: "Maison Neue Bold", Calibri, Arial, Roboto, sans-serif;
						font-size: 13px;
						font-style: normal;
						line-height: 40px;
						letter-spacing: 0.04em;
					}

					.attribute-selector-container {
						display: inline-block;
						float: none;
						text-align: left;
					}

					.btn-primary {
						
						display: inline-block;
						float: none;
						top: -17px;
					}
				}

				@media(min-width: 768px) and (max-width:991px){
					
					h2 {
						display: none;
					}

					.facets-sticky-nav {

						.col-sm-6 {
							width: 27.667%;
							margin-left: 0px;
						}

						.filter-by-selector {
							width: 22%;
						}
					}
				}
			}
		}
	}
}

.category,
.collection {

	.layout-container:not(.header-bottom-hide){

		.header-menu-cart {

			@media(min-width: 768px) and (max-width: 991px){
				right: 0px;
			}
		}
	}
}

.community {

	#main-container .header-menu-wrapper {

		left: 0px !important;

		.centered-absolute {

			position: absolute;
			top: 0px;
			left: 40px;
			right: 0px;
			width: auto;
			height: 41px;
			text-align: center;

			.container {

				position: relative;
				display: inline-block;
				height: 41px;

				h2 {
					padding: 0 !important;
					text-align: left;

					@media(max-width: 1300px){
						margin-left: 20%;
					}

					@media(max-width: 1115px){
						margin-left: 22.5%;
					}
				}

				.social-icons {

					position: absolute;
					right: 26px;

					a {
						display: inline-block;
					    position: relative;
					    width: auto;
					    float: none;
					    font-size: 23px;
					    padding: 0 12px;
					    color: #7e8183;
					    line-height: 36px;
					    width: 56px;
					    text-align: center;
					}
				}
			}
		}
	}
}

.pdp {

	.header-compact-pdp {

		#site-header {
			border-bottom: none !important;
		}
		
		#content .header-menu-wrapper {
			left: 155px;
		}
	}

	.header-compact-pdp .header-secondary-wrapper.pdp {

		border-bottom: 1px solid rgba(42, 42, 42, 0.12);

		.header-logo-wrapper .header-logo-content {

			padding: 0 40px 0 0;
			border-left: none;

			.header-logo {
				margin-left: 0px;
			}
		}
	}
}
