
#image-overlay-vertical, 
#image-overlay-horizontal, 
#image-overlay {

	left: 0px !important;
	top: 0px !important;
	width: 100% !important;
	height: 100% !important;
	padding: 0 !important;

	.modal-dialog {

		width: 100%;
	}

	button.close {
		top: 25px;
	}

	.modal-content {

		position: relative;
		border: 0 none;
		background: transparent;
		overflow: visible;
		margin-top: 0px;

		.modal-slider {
			position: relative;
		}

		.slides {

			position: relative;
			top: 0px;
			width: 100%;
			height: auto;
			z-index: 1;
			overflow: hidden;

			.slide {

				position: relative;
				visibility: visible;
				top: 0px;
				width: 100%;
				height: auto;
				opacity: 0;

				&:not(:first-child){

					position: absolute;
					width: 100%;
					height: auto;
					visibility: hidden;
				}

				.slide-image {

					position: relative;
					top: 0px;
					left: 0px;
					width: 100%;
					height: auto;
				}
			}

		} // .gender-slides

		.jumbo-slider-controls {

			position: absolute;
			width: 100%;
			top: 0px;
			z-index: 50;

			.jumbo-arrows {

				position: absolute;
				width: 100%;

				@media(min-width: 768px) and (max-width:991px){
					display: none;
				}

				.slider-arrow {

					position: absolute;
					display: block;
					top: 0px;
					width: auto;
					height: auto;
					visibility: visible;
					margin-top: -38px;

					&.slider-arrow-prev {
						left: 0px;
					}

					&.slider-arrow-next {
						right: 0px;
					}

					button {
						padding: 30px;
						background: transparent;
					}

					svg {

						position: relative;
						overflow: visible;
						width: 70px;
						height: 15px;

						* {
							fill: #fdfdfd !important;
						}
					}
				}
			}

			.jumbo-pagination {

				position: absolute;
				left: 0px;
				right: 0px;
				text-align: center;

				.jumbo-pagination-item {

					position: relative;
					display: inline-block;
					width: 7px;
					height: 7px;
					background: transparent;
					opacity: 0.5;
					padding: 15px;

					cursor: pointer;

					-webkit-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					   -moz-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					     -o-transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					        transition: opacity 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

					-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					     -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
					        transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

					&:not(:last-child){
						margin-right: 5px;
					}

					.slider-pager-icon {

						position: absolute;
						top: 15px - 3px;
						left: 15px - 3px;
						width: 6px;
						height: 6px;
						border: 4px solid #fff;

						-webkit-border-radius: 7px;
						-moz-border-radius: 7px;
						border-radius: 7px;

						-webkit-transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						   -moz-transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						     -o-transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						        transition: all 240ms cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */

						-webkit-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						   -moz-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						     -o-transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); 
						        transition-timing-function: cubic-bezier(0.280, 0.010, 0.175, 1.000); /* custom */
					}

					.slash {

						position: absolute;
						visibility: hidden;
						top: 14px;
						left: -12px;
						width: 56px;
						height: 2px;
						background: #fff;
					}

					&:hover {
						opacity: 1 !important;
					}

					&.selected {
						
						opacity: 1 !important;

						.slider-pager-icon {
						
							top: 15px - 10px;
							left: 15px - 10px;
							width: 20px;
							height: 20px;
							border: 2px solid #fff;		

							-webkit-border-radius: 20px;
							-moz-border-radius: 20px;
							border-radius: 20px;
						}			
					}
				}
			}
		}

		.slides-container {

			position: relative;
			overflow: hidden;
		}

		.jumbo-slider-shadows {

			position: absolute;
			top: 0px;
			width: 100%;
			height: 100%;
			pointer-events: none;
			z-index: 2;

			.shadow {

				position: absolute;
				top: 0px;
				width: 356px;
				height: 100%;

				&.shadow-left {

					display: none;
					left: 0px;
					background: url('../img/slider-control-gradient-left.png') repeat-y left top;
					opacity: 0;
				}

				&.shadow-right {

					display: none;
					right: 0px;
					background: url('../img/slider-control-gradient-right.png') repeat-y right top;
					opacity: 0;
				}
			}
		}
	}
}