.btn-video-play {
	
	display: block;
	position: relative;
	width: 80px;
	height: 80px;
	border: 0 none;
	border-radius: 0;
	opacity: 0;

	padding: 1px 0px 0px 5px;
	background-color: transparent;
	margin-top: 10px;
	font-size: 23px;
	margin-bottom: 15px;

	.circle {

		position: absolute;
		top: -3px;
		left: -3px;
		width: 80px;
		height: 80px;

		svg * {

			stroke: rgba(255,255,255,1);
			stroke-dasharray: 360px;
			stroke-width: 5px !important;
			stroke-dashoffset: -360px; 
		}

		&.hover {

			svg * {

				stroke: rgba(255,255,255,1);
				stroke-dasharray: 360px 110px;
				stroke-width: 5px !important;
				stroke-dashoffset: -360px; 
			}
		}
	}

	i {
		font-size: 30px;
		line-height: 80px;
		width: 100%;
		text-align: center;
	}
}